import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EmailSvg, PasswordSvg } from "../data/Data";
import { HomeButton, Input } from "../controller/components"; 
import { useAuth } from "../contexts/AuthContext";
import { Toast } from "../controller/Brain";
import PageIsLoading from "../components/general/PageIsLoading";

const Login = () => {
  const { login, isloading, currentUser} = useAuth();

  const navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  function handleRedirectToOrBack() {
    let targetRoute = "";
    targetRoute = "/";
    navigate(targetRoute);
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!loginEmail) {
      Toast.fire({
        icon: "error",
        title: "Email is required",
      });
    } else if (!isValidEmail(loginEmail)) {
      Toast.fire({
        icon: "error",
        title: "Enter a valid email format",
      });
    } else if (!loginPassword) {
      Toast.fire({
        icon: "error",
        title: "Password is required",
      });
    } else {
      try {
        setLoading(true);
        const userCredential = await login(loginEmail, loginPassword);
        const user = userCredential.user;

        if (user && currentUser !== null) {
          handleRedirectToOrBack();
        } else if (user === null) {
          // If email is not verified, show a toast message
          Toast.fire({
            icon: "warning",
            title: "Email not verified",
            text: "Please check your email and click the verification link to log in.",
          });
          setLoading(false);
        }
      } catch (error) {
        console.error("Login failed:", error);
        if (error.code === "auth/user-not-found") {
          Toast.fire({
            icon: "error",
            title: "User not found. Please register now.",
          });
          setLoading(false);
        } else if (error.code === "auth/wrong-password") {
          Toast.fire({
            icon: "error",
            title: "Incorrect password. Please try again.",
          });
          setLoading(false);
        } else {
          Toast.fire({
            icon: "error",
            title: "User not found. Please register now.",
          });
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="form-container">
      {isloading ? (
        <PageIsLoading />
      ) : (
        <div>
          <form className="form">
            <div className="form-title">Login</div>
            <Input
              title="Email"
              svg={EmailSvg}
              placeholder="Enter your Email"
              type="text"
              id="email"
              name="email"
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
            />
            <Input
              title="Password"
              svg={PasswordSvg}
              placeholder="Enter your Password"
              type="password"
              id="password"
              name="password"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
            />

            <div className="flex-row">
              <div className="radio-flex">
                <input type="checkbox" className="lp-checkbox sc-checkbox" />
                <label>Remember me </label>
              </div>
              <Link to={"/forgot-password"} className="span">
                Forgot password?
              </Link>
            </div>
            <HomeButton
              text={"LOG IN"}
              loading={loading}
              onClick={!loading ? handleLogin : undefined}
            />
            <p className="p">
              Don't have an account?{" "}
              <Link to={"/register"} className="span">
                Sign Up
              </Link>
            </p>
          </form>
        </div>
      )}
    </div>
  );
};

export default Login;
