import React from "react";
import MangeLink from "../../components/cards/MangeLink";

const Overview = () => {
  return (
    <div className="flex-center">
      <div className="form">
        <MangeLink
          url={"/admin/manage/matrimony"}
          title={"Matrimony"}
          text={"Manage Profiles"}
        />
        <MangeLink
          url={"/admin/manage/admin"}
          title={"Admin"}
          text={"Manage Admin"}
        />
        <MangeLink
          url={"/admin/manage/user"}
          title={"User"}
          text={"Manage User"}
        />
        <MangeLink url={"/admin/view/logs"} title={"Logs"} text={"View Logs"} />
        <MangeLink
          url={"/admin/manage/info"}
          title={"Manage"}
          text={"Manage Info"}
        />
      </div>
    </div>
  );
};

export default Overview;
