import React, { useContext, useEffect, useState } from "react";
import { ProjectName } from "../data/Data";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CookieConsent from "../components/general/CookieConsent";
import Board from "../components/cards/Board";
import { ThemeContext } from "../contexts/ThemeContext";
import { useGeneralBrain } from "./Brain";

const Container = ({ card: Card, title, path, board }) => {
  const { theme } = useContext(ThemeContext);

  const { setwho } = useGeneralBrain();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.title = `${capitalizeFirstLetter(title)} - ${ProjectName}`;
    window.scrollTo(0, 0);
    setOpen(false);
    setwho(path);
  }, [title, path, setwho]);

  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (theme === "light") {
      import("sweetalert2/dist/sweetalert2.min.css");
    } else {
      import("@sweetalert2/theme-dark/dark.scss");
    }
  }, [theme]);

  return (
    <>
      <Navbar open={open} setOpen={setOpen} />
      <div className="general-container">
        {board && <Board title={board} />}
        <div className="general-card-class">
          <Card />
        </div>
      </div>
      <>
        <Footer />
        <CookieConsent />
      </>
    </>
  );
};

export default Container;
