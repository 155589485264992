import React, { useEffect, useState } from "react";
import { ButtonIcon } from "../../controller/components";
import SearchEngine from "../../controller/SearchEngine";
import { useNavigate } from "react-router-dom";
import { UserIcon, UsersIcon } from "../../data/Data";
import ProfileCard from "../../components/cards/ProfileCard";
import Profile from "../../components/cards/Profile";
import { db } from "../../utils/init-firebase";
import { doc, getDoc } from "firebase/firestore";
import { useGeneralBrain } from "../../controller/Brain";
import NothingYet from "../../components/general/NothingYet";
import PageWait from "../../components/general/PageWait";

const Matrimony = () => {
  const navigate = useNavigate();
  const { userData } = useGeneralBrain();
  const [id, setId] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  const [data, setData] = useState({});
  const [info, setInfo] = useState({});
  const [myData, setMyData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [loading, setLoading] = useState(true); // Loading state

  const handleViewProfile = () => {
    setShowProfile(false);
  };

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const live1 = userData?.gender === "Male" ? "livefemale1" : "livemale1";
        const docRef = doc(db, "liveMatrimonys", live1);
        const liveShortlistIdsRef = doc(
          db,
          "liveShortlistIds",
          userData.userId
        );
        const docSnap = await getDoc(docRef);
        const liveShortlistIdsSnap = await getDoc(liveShortlistIdsRef);

        if (liveShortlistIdsSnap.exists()) {
          const logData = liveShortlistIdsSnap.data().ids || [];
          setMyData(logData);
        }

        if (docSnap.exists()) {
          const logData = docSnap.data();
          setData(logData);
        }

        setLoading(false); // Data fetching complete
      } catch (error) {
        console.error("Error fetching logs:", error);
        setLoading(false); // Set loading to false even on error
      }
    };

    fetchLogs();
  }, [userData]);

  useEffect(() => {
    if (data && myData) {
      const filteredDatas = Object.entries(data)
        .filter(([d106, d]) => {
          return !myData.includes(d106) && d.d107 !== "suspended";
        })
        .reduce((obj, [d106, d]) => {
          obj[d106] = d;
          return obj;
        }, {});

      let sortedWorks = Object.values(filteredDatas);
  
      if (sortBy === "name") {
        sortedWorks = sortedWorks.sort((a, b) => a.d101.localeCompare(b.d101));
      } else if (sortBy === "age") {
        sortedWorks = sortedWorks.sort((a, b) => a.d102 - b.d102);
      }
  
      setInfo(sortedWorks);
    }
  }, [myData, data, sortBy]);

  const handleSearch = (searchText) => {
    const filteredDatas = Object.entries(data)
      .filter(([d106, d]) => {
        return (
          !myData.includes(d106) &&
          (d.d101.toLowerCase().includes(searchText.toLowerCase()) ||
            d.d102 === parseInt(searchText))
        );
      })
      .reduce((obj, [d106, d]) => {
        obj[d106] = d;
        return obj;
      }, {});

    setInfo(filteredDatas);
  };

  return (
    <div className="matrimony-container">
      {!showProfile && ( // Render SearchEngine, Buttons, and PageWait conditionally
        <div className="matrimony-first">
          <ButtonIcon
            icon={UsersIcon}
            text={"View Shortlist Profile"}
            onClick={() => navigate("/matrimony/view/shortlist")}
            rounded={true}
          />
          <ButtonIcon
            icon={UserIcon}
            text={"Manage My Profile"}
            onClick={() => navigate("/matrimony/manage/profile")}
            rounded={true}
          />
          <SearchEngine onSearch={handleSearch} setSortBy={setSortBy} />
        </div>
      )}
      {loading && <PageWait />} {/* Render PageWait while loading */}
      {!showProfile &&
        !loading && ( // Render ProfileCard or NothingYet conditionally
          <div>
            {Object.keys(info).length > 0 ? (
              <div className="profile-card-container">
                {Object.keys(info).map((key) => (
                  <ProfileCard
                    key={key}
                    setShowProfile={setShowProfile}
                    setId={setId}
                    data={info[key]}
                    setMyData={setMyData}
                  />
                ))}
              </div>
            ) : (
              <NothingYet />
            )}
          </div>
        )}
      {showProfile && <Profile onClick={handleViewProfile} id={id} />}
    </div>
  );
};

export default Matrimony;
