export const Input = ({
  title,
  svg: Svg,
  placeholder,
  type,
  value,
  id,
  name,
  onChange,
}) => {
  return (
    <div className="flex-column">
      <label className="input-label">{title}</label>
      <div className="inputForm">
        <Svg />
        <input
          placeholder={placeholder}
          className="input theme"
          type={type}
          value={value}
          id={id}
          name={name}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export const ButtonIcon = ({
  icon: Icon,
  text,
  onClick,
  loading,
  rounded,
}) => {
  return (
    <button
      className={`button-icon ${rounded ? "button-rounded" : ""}`}
      onClick={onClick}
    >
      {Icon && (
        <span className="button-icon">
          <Icon />
        </span>
      )}
      <span className="button-text">{loading ? "Wait..." : text}</span>
    </button>
  );
};

export const HomeButton = ({ text, onClick, loading }) => {
  return (
    <button className="button-submit" onClick={onClick}>
      {loading ? "Loading..." : text}
    </button>
  );
};

export const InputButton = ({
  ButtonText,
  title,
  placeholder,
  type,
  value,
  id,
  name,
  onChange,
  onClick,
}) => {
  return (
    <div className="button-input-container">
      <input
        placeholder={placeholder}
        className="input-btn theme"
        type={type}
        value={value}
        id={id}
        name={name}
        onChange={onChange}
      />
      <div className="button-input-class" onClick={onClick}>
        {ButtonText}
      </div>
    </div>
  );
};
