import React, { useState } from "react";
import { Toast } from "../../controller/Brain";
import { storage, db } from "../../utils/init-firebase";
import {
  collection, 
  doc,
  getDoc,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import Swal from "sweetalert2";
import { useGeneralBrain } from "../../controller/Brain";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const StepperForm = ({ setShowForm, data }) => {
  const { generateUid8, userId } = useGeneralBrain();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    phone: "",
    mobile: "",
    email: "",
    time: serverTimestamp(),
    imgURLs: [],
    gender: "",
    name: "",
    status: "unapproved",
    aboutMe: "",
    location: "",
    residingAddress: "",
    birthDate: "",
    birthDay: "",
    birthTime: "",
    birthPlace: "",
    height: "",
    gotra: "",
    bloodGroup: "",
    jobInfo: "",
    education: "",
    hobbies: "",
    job: "",
    organization: "",
    workingAs: "",
    workplace: "",
    annualIncome: "",
    business: "",
    fathersName: "",
    fathersProfession: "",
    mothersName: "",
    mothersProfession: "",
    fathersAddress: "",
    fathersPhone: "",
    fathersMobile: "",
  });
  const [profileImages, setProfileImages] = useState([]);

  const validateFormData = () => {
    switch (step) {
      case 2:
        if (
          !formData.name ||
          !formData.gender ||
          !formData.phone ||
          !formData.location ||
          !formData.mobile ||
          !formData.email ||
          !formData.residingAddress ||
          !profileImages.length
        ) {
          return {
            isValid: false,
            errorMessage: "All basic details are required",
          };
        }
        break;
      case 3:
        if (
          !formData.birthDate ||
          !formData.birthTime ||
          !formData.birthPlace ||
          !formData.height ||
          !formData.gotra ||
          !formData.bloodGroup
        ) {
          return {
            isValid: false,
            errorMessage: "All birth details are required",
          };
        }
        // Validate birth date for age of 18 or older
        const birthDate = new Date(formData.birthDate);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (
          monthDifference < 0 ||
          (monthDifference === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        if (age < 18) {
          return {
            isValid: false,
            errorMessage: "You must be at least 18 years old to register",
          };
        }
        break;
      case 4:
        if (
          !formData.job ||
          !formData.organization ||
          !formData.workingAs ||
          !formData.workplace ||
          !formData.annualIncome ||
          !formData.business
        ) {
          return {
            isValid: false,
            errorMessage: "All professional details are required",
          };
        }
        break;
      case 5:
        if (
          !formData.fathersName ||
          !formData.fathersProfession ||
          !formData.mothersName ||
          !formData.mothersProfession ||
          !formData.fathersAddress ||
          !formData.fathersPhone
        ) {
          return {
            isValid: false,
            errorMessage: "All family details are required",
          };
        }
        break;
      default:
        break;
    }
    return { isValid: true, errorMessage: "" };
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setProfileImages((prevImages) => [...prevImages, ...files]);
  };

  const removeImage = (index) => {
    const updatedImages = [...profileImages];
    updatedImages.splice(index, 1);
    setProfileImages(updatedImages);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormData((prevData) => {
      const updatedFormData = {
        ...prevData,
        [name]: value,
      };
  
      if (name === 'birthDate') {
        const date = new Date(value);
        const dayIndex = date.getDay();
        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        updatedFormData.birthDay = weekdays[dayIndex];
      }
  
      return updatedFormData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { isValid, errorMessage } = validateFormData();
    if (!isValid) {
      Toast.fire({
        icon: "error",
        title: errorMessage,
      });
      setLoading(false);
      return;
    }
    try {
      const uploadTasks = profileImages.map((image) => {
        const storageRef = ref(storage, `matrimony/${userId}/${image.name}`);
        return uploadBytesResumable(storageRef, image);
      });

      const adminsDocRef = doc(db, "mails", "admins");
      const adminsDocSnap = await getDoc(adminsDocRef);

      if (!adminsDocSnap.exists()) {
        throw new Error("No admins found in the document");
      }

      const adminEmails = adminsDocSnap.data().admins;

      await Promise.all(uploadTasks);
      const imgURLs = await Promise.all(
        profileImages.map(async (image) => {
          const storageRef = ref(storage, `matrimony/${userId}/${image.name}`);
          return await getDownloadURL(storageRef);
        })
      );

      const updatedFormData = { ...formData };
      updatedFormData.imgURLs = imgURLs;

      await runTransaction(db, async (transaction) => {
        const userRef = doc(db, "users", userId);
        transaction.update(userRef, {
          isSubcribedStatus: "active",
          gender: updatedFormData.gender,
        });

        const matrimonyRef = doc(db, "matrimonys", userId);
        transaction.set(matrimonyRef, updatedFormData);

        const code = "code";
        const docRef = doc(db, "info", "users");
        transaction.update(docRef, {
          [`${userId}.${code}`]: 1110000,
        });

        const uniqueLogId = generateUid8();
        const logRef = doc(db, "info", "logs");
        transaction.update(logRef, {
          [uniqueLogId]: {
            info: "Register matrimony profile",
            time: serverTimestamp(),
            username: updatedFormData.name,
          },
        });

        for (const adminEmail of adminEmails) {
          const uniqueUrl = `https://nevewanisamaj.in/approve-matrimony/hash-code*7896*admin*approval*yes*from*email*${userId}*${updatedFormData.name}*${updatedFormData.email}`;
          const adminEmailRef = doc(collection(db, "sendEmails"));
          transaction.set(adminEmailRef, {
            recipient: adminEmail,
            subject: "New Matrimony Registration",
            title: `A new matrimony: ${updatedFormData.name}`,
            content: `A new user has registered for matrimony with the email ${updatedFormData.email}. Click the link to approve.`,
            link: uniqueUrl,
            timestamp: serverTimestamp(),
          });
        }
      });

      setShowForm(false);
      Toast.fire({
        icon: "success",
        title: "Profile uploaded successfully",
      }).then(() => {
        setLoading(false);
      });
    } catch (error) {
      console.error("Error", error);
      Swal.fire("Error!", "Failed. Please try again.", "error").then(() => {
        setLoading(false);
      });
    }
  };

  const nextStep = () => {
    // Validate form data before proceeding to the next step
    const { isValid, errorMessage } = validateFormData();
    if (!isValid) {
      Toast.fire({
        icon: "error",
        title: errorMessage,
      });
      return;
    }
    setStep((prevStep) => prevStep + 1);
    window.scrollTo(0, 0);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
    window.scrollTo(0, 0);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="step">
            <img src={data?.qrCodeImage} alt="Scan" className="image" />
            <h4>Scan and Make Payment of Rs. {data?.amount}</h4>
            <button onClick={nextStep}>I've sent the money</button>
          </div>
        );

      case 2:
        return (
          <div className="step">
            <h4>Basic Details</h4>
            <label htmlFor="">Select Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <label htmlFor="">Enter Full Name</label>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Location</label> 
            <input
              type="text"
              name="location"
              placeholder="Location"
              value={formData.location}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Residing Address</label>
            <input
              type="text"
              name="residingAddress"
              placeholder="Residing Address"
              value={formData.residingAddress}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Phone</label>
            <input
              type="number"
              name="phone"
              placeholder="Enter Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
             <label htmlFor="">Enter Mobile</label>
            <input
              type="number"
              name="mobile"
              placeholder="Enter Mobile"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
             <label htmlFor="">Enter Email</label>
            <input
              type="text"
              name="email"
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <div>
              <div className="label-stepper">Add Profile Picture:</div>
              <div className="image-container-scope">
                {profileImages.map((image, index) => (
                  <div key={index} className="preview-container">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Preview"
                      className="preview-pfp"
                    />
                    <div
                      className="remove-btn"
                      onClick={() => removeImage(index)}
                    >
                      <div>x</div>
                    </div>
                  </div>
                ))}
                {profileImages.length < 4 && (
                  <label htmlFor="profileImage" className="add-image">
                    <span>+</span>
                    <input
                      id="profileImage"
                      type="file"
                      name="profileImage"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </label>
                )}
              </div>
            </div>

            <div className="btn-flex">
              <button onClick={nextStep}>Next</button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="step">
            <h4>Birth Details</h4>
            <label htmlFor="">Enter Birth Date</label>
            <input
              type="date"
              name="birthDate"
              placeholder="Birth Date"
              value={formData.birthDate}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Birth Time</label>
            <input
              type="time"
              name="birthTime"
              placeholder="Birth Time"
              value={formData.birthTime}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Birth Place</label>
            <input
              type="text" 
              name="birthPlace"
              placeholder="Birth Place"
              value={formData.birthPlace}
              onChange={handleChange}
              required
            />

            <label htmlFor="">Enter Height (Ft)</label>
            <input
              type="text"
              name="height"
              placeholder="Height (Ft)"
              value={formData.height}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Gotra</label>
            <input
              type="text"
              name="gotra"
              placeholder="Gotra"
              value={formData.gotra}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Select Blood Group</label>
            <select
              name="bloodGroup"
              value={formData.bloodGroup}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
            </select>

            <label htmlFor="">Enter Education</label>
            <input
              type="text"
              name="education"
              placeholder="Enter Education"
              value={formData.education}
              onChange={handleChange}
              required
            />

           <label htmlFor="">Enter Hobbies</label>
            <input
              type="text"
              name="hobbies"
              placeholder="Enter Hobbies"
              value={formData.hobbies}
              onChange={handleChange}
              required
            />

            <div className="btn-flex">
              <button onClick={prevStep}>Previous</button>
              <button onClick={nextStep}>Next</button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="step">
            <h4>Professional Details</h4>

            <label htmlFor="">Enter Job</label>
            <input
              type="text"
              name="job"
              placeholder="Job"
              value={formData.job}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Organization</label>
            <input
              type="text"
              name="organization"
              placeholder="Organization"
              value={formData.organization}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Working As</label>
            <input
              type="text"
              name="workingAs"
              placeholder="Working As"
              value={formData.workingAs}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Workplace</label>
            <input
              type="text"
              name="workplace"
              placeholder="Workplace"
              value={formData.workplace}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Annual Income (in Lakhs)</label>
            <input
              type="number"
              name="annualIncome"
              placeholder="Annual Income (in Lakhs)"
              value={formData.annualIncome}
              onChange={handleChange}
              required
            />
             <label htmlFor="">Select  Job Info</label>
             <select
              name="jobInfo"
              value={formData.jobInfo}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Permanent">Permanent</option>
              <option value="Temporary">Temporary</option>
            </select>
            <label htmlFor="">Enter Business if any</label>
            <input
              type="text"
              name="business"
              placeholder="Business"
              value={formData.business}
              onChange={handleChange}
              required
            />
            <div className="btn-flex">
              <button onClick={prevStep}>Previous</button>
              <button onClick={nextStep}>Next</button>
            </div>
          </div>
        );

      case 5:
        return (
          <div className="step">
            <h4>Family Details</h4>

            <label htmlFor="">Enter Fathers Name</label>
            <input
              type="text"
              name="fathersName"
              placeholder="Fathers Name"
              value={formData.fathersName}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Profession</label>
            <input
              type="text"
              name="fathersProfession"
              placeholder="Fathers Profession"
              value={formData.fathersProfession}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Mothers Name</label>
            <input
              type="text"
              name="mothersName"
              placeholder="Mothers Name"
              value={formData.mothersName}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Profession</label>
            <input
              type="text"
              name="mothersProfession"
              placeholder="Mothers Profession"
              value={formData.mothersProfession}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Residing Address</label>
            <input
              type="text"
              name="fathersAddress"
              placeholder="Residing Address"
              value={formData.fathersAddress}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Phone</label>
            <input
              type="tel"
              name="fathersPhone"
              placeholder="Phone"
              value={formData.fathersPhone}
              onChange={handleChange}
              required
            />
            <label htmlFor="">Enter Mobile</label>
            <input
              type="tel"
              name="fathersMobile"
              placeholder="Mobile"
              value={formData.fathersMobile}
              onChange={handleChange}
              required
            />
            <div className="btn-flex">
              <button onClick={prevStep} disabled={loading}>
                Previous
              </button>
              <button disabled={loading} onClick={handleSubmit}>
                {loading ? "Uploading..." : "Finish"}
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="stepper-container">
      <h3>Step: {step} / 5 </h3>
      <div>{renderStep()}</div>
    </div>
  );
};

export default StepperForm;
