import React from 'react'

const PageWait = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '300px' }} className='pagewait'>
        <div class="spinner center visually-hidden">
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
    <div class="spinner-blade"></div>
</div>
    </div>
  )
}

export default PageWait