import React, { useEffect, useState } from "react";
import { ButtonIcon } from "../../controller/components";
import SearchEngine from "../../controller/SearchEngine";
import { useNavigate } from "react-router-dom";
import { UsersIcon } from "../../data/Data";
import ProfileCard from "../../components/cards/ProfileCard";
import { db } from "../../utils/init-firebase";
import { doc, onSnapshot } from "firebase/firestore";
import NothingYet from "../../components/general/NothingYet";
import PageWait from "../../components/general/PageWait";

const ManageMatrimony = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [info, setInfo] = useState({});
  const [myData, setMyData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const fedocRef = doc(db, "liveMatrimonys", "livefemale1");
        const madocRef = doc(db, "liveMatrimonys", "livemale1");

        const unsubscribeFemale = onSnapshot(fedocRef, (docSnap) => {
          if (docSnap.exists()) {
            setData((prevData) => ({
              ...prevData,
              ...docSnap.data(),
            }));
          }
          setLoading(false);
        });

        const unsubscribeMale = onSnapshot(madocRef, (docSnap) => {
          if (docSnap.exists()) {
            setData((prevData) => ({
              ...prevData,
              ...docSnap.data(),
            }));
          }
          setLoading(false);
        });

        return () => {
          unsubscribeFemale();
          unsubscribeMale();
        };
      } catch (error) {
        console.error("Error fetching logs:", error);
        setLoading(false);
      }
    };

    fetchLogs();
  }, []);

  useEffect(() => {
    if (data && myData) {
      const filteredDatas = Object.entries(data)
        .filter(([d106, d]) => {
          return !myData.includes(d106);
        })
        .reduce((obj, [d106, d]) => {
          obj[d106] = d;
          return obj;
        }, {});

      console.log(filteredDatas);
      let sortedWorks = Object.values(filteredDatas);

      if (sortBy === "name") {
        sortedWorks = sortedWorks.sort((a, b) => a.d101.localeCompare(b.d101));
      } else if (sortBy === "age") {
        sortedWorks = sortedWorks.sort((a, b) => a.d102 - b.d102);
      }

      setInfo(sortedWorks);
    }
  }, [myData, data, sortBy]);

  const handleSearch = (searchText) => {
    const filteredDatas = Object.entries(data)
      .filter(([d106, d]) => {
        return (
          !myData.includes(d106) &&
          (d.d101.toLowerCase().includes(searchText.toLowerCase()) ||
            d.d102 === parseInt(searchText))
        );
      })
      .reduce((obj, [d106, d]) => {
        obj[d106] = d;
        return obj;
      }, {});

    setInfo(filteredDatas);
  };

  return (
    <div className="matrimony-container">
      <div className="matrimony-first">
        <ButtonIcon
          icon={UsersIcon}
          text={"Manage User"}
          onClick={() => navigate("/admin/manage/user")}
          rounded={true}
        />
        <ButtonIcon
          icon={UsersIcon}
          text={"Manage Admin"}
          onClick={() => navigate("/admin/manage/admin")}
          rounded={true}
        />
        <SearchEngine onSearch={handleSearch} setSortBy={setSortBy} />
      </div>
      {loading && <PageWait />} {/* Render PageWait while loading */}
      {!loading && (
        <div>
          {Object.keys(info).length > 0 ? (
            <div className="profile-card-container">
              {Object.keys(info).map((key) => (
                <ProfileCard key={key} data={info[key]} setMyData={setMyData} />
              ))}
            </div>
          ) : (
            <NothingYet />
          )}
        </div>
      )}
    </div>
  );
};

export default ManageMatrimony;
