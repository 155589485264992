import "./App.css";
import "../src/styles/General.css";
import AppRouter from "./components/AppRouter";
import ThemeProvider from "./providers/ThemeProvider";
import { ThemeContextProvider } from "./contexts/ThemeContext";
import AuthContextProvider from "./contexts/AuthContext";
import { GeneralBrainProvider } from "./controller/Brain";
import { DataProvider } from "./contexts/DataContext";

function App() {


  return (
    <div className="App">
      <AuthContextProvider>
      <DataProvider>
        <GeneralBrainProvider>
          <ThemeContextProvider>
            <ThemeProvider>
              <div className="container">
                <div className="wrapper">
                  <AppRouter />
                </div>
              </div>
            </ThemeProvider>
          </ThemeContextProvider>
        </GeneralBrainProvider>
        </DataProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
