import React, { useEffect, useState } from "react";
import { db } from "../../utils/init-firebase";
import { doc, getDoc } from "firebase/firestore";
const Contact = () => {
  const [data, setData] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "info", "adminmanageInfo");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      {data && (
        <div>
          <h2>Contact Us</h2>
          <p>If you need further assistance, please feel free to contact us:</p>
          <p>Email: {data.contactEmail}</p>
          <p>Phone: {data.contactNumber}</p>
        </div>
      )}
    </div>
  );
};

export default Contact;
