import React, { useState } from "react";
import { Input } from "../../controller/components";
import { PasswordSvg } from "../../data/Data";
import { Toast } from "../../controller/Brain";
import { useAuth } from "../../contexts/AuthContext";


const ChangePassword = ({ setshow }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { UpdateUserPassword } = useAuth();

  const handleValidation = async (e) => {
    e.preventDefault();

    if (!currentPassword) {
      Toast.fire({
        icon: "error",
        title: "Current Password is required",
      });
    } else if (!password) {
      Toast.fire({
        icon: "error",
        title: "New Password is required",
      });
    } else if (!confirmPassword) {
      Toast.fire({
        icon: "error",
        title: "Confirm Password is required",
      });
    } else if (password !== confirmPassword) {
      Toast.fire({
        icon: "error",
        title: "Passwords must match",
      });
    } else {
      await UpdateUserPassword(currentPassword, password);
      setshow(false);
    }
  };

  return (
    <div className="form-container">
      <div>
        <form className="form">
          <div className="form-title">Reset Password</div>
          <Input
            title="Current password"
            svg={PasswordSvg}
            placeholder="Enter current password"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <Input
            title="New password"
            svg={PasswordSvg}
            placeholder="Enter new password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            title="Confirm Password"
            svg={PasswordSvg}
            placeholder="Enter confirm new password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div onClick={() => setshow(false)} className="underline">
            Go back
          </div>
          <button onClick={handleValidation} className="button-submit">
            Update Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
