import React, { useState } from "react";
import Contact from "../components/general/Contact";

const Help = () => {
  const faqs = [
    {
      question: "How do I create an account?",
      answer:
        'To create an account, click on the "Sign Up" button and follow the instructions.',
    },
    {
      question: "How do I search for matches?",
      answer:
        "To search for matches, go to the search page and use the filters to refine your search.",
    },
    {
      question: "How do I edit my profile?",
      answer:
        'To edit your profile, go to your profile page and click on the "Edit Profile" button.',
    },
    {
      question: "How do I contact a member?",
      answer:
        'To contact a member, visit their profile page and click on the "Contact" button.',
    },
    {
      question: "How do I delete my account?",
      answer:
        'To delete your account, go to your account settings and select the "Delete Account" option.',
    },
  ];

  const [openIndex, setOpenIndex] = useState(-1);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <div className="flex-center">
      <div className="form">
        <h1>Help and Support</h1>
        <p>Welcome to NWSVP Matrimony Help and Support page!</p>

        <h2>FAQs</h2>
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleToggle(index)}>
              <span>{faq.question}</span>
              <span>{openIndex === index ? "-" : "+"}</span>
            </div>
            {openIndex === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
        <Contact />
      </div>
    </div>
  );
};

export default Help;
