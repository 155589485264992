import React from "react";
import { Link } from "react-router-dom";

const MangeLink = ({ text, url, title, onClick }) => {
  return (
   <div className="manage-link-parent" onClick={onClick}>
      <Link to={url} className="manage-link-card box-shadow">
        <div>
          <h3 className="manage-link-title">{title}</h3>
          <div className="manage-link-text">{text}</div>
        </div>
      </Link>
   </div>
  );
};

export default MangeLink;
