import React from "react";
import { Link } from "react-router-dom";

const SideLink = ({ svg: Svg, url, text, onClick }) => {
  return (
    <Link to={url} className="grid-center">
      <div onClick={onClick} className="sidelink-container box-shadow">
        <Svg />
        <div className="navbar-sidelink dart">{text}</div>
      </div>
    </Link>
  );
};

export default SideLink;
