// Table.js
import React, { useState } from "react";

const Table = ({ data, columns, actions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
            {actions && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
        {currentData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.keys(row).map((key, columnIndex) => (
                <td key={columnIndex}>
                  {key === "img" ? (
                    <img
                      src={row[key]}
                      alt="Profile"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  ) : key === "id" ? (
                    row[key].substring(0, 3)
                  ) : (
                    row[key]
                  )}
                </td>
              ))}
              {actions && (
                <td>
                  {actions.map((action, index) => (
                    <button key={index} onClick={() => action.onClick(row)}>
                      {action.label}
                    </button>
                  ))}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="table-last-container">
        <div className="table-current-total">
          <span>
            {currentPage} / {totalPages}
          </span>
        </div>
        {totalPages > 1 && (
          <div className="pagination">
            {currentPage !== 1 && (
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Previous
              </button>
            )}
            {currentPage !== totalPages && (
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
