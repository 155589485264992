import React, { useEffect, useState } from "react";
import Table from "../../components/cards/Table";
import { Toast } from "../../controller/Brain";
import { InputButton } from "../../controller/components";
import { db } from "../../utils/init-firebase";
import {
  doc,
  getDoc,
  updateDoc,
  deleteField,
  runTransaction,
  serverTimestamp,
  onSnapshot,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import Swal from "sweetalert2";
import { showConfirmDialog, useGeneralBrain } from "../../controller/Brain";

const ManageAdmin = () => {
  const { userNickname, generateUid8, TimeAgo } = useGeneralBrain();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [data, setData] = useState([]);

  const columns = ["ID", "Name", "Email", "Join Date", "Added By"];

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const docRef = doc(db, "info", "admins");
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
          const fetchedLogs = [];

          // Check if snapshot exists and contains data
          if (snapshot.exists()) {
            const logData = snapshot.data();
            const sortedKeys = Object.keys(logData)
              .sort((a, b) => {
                const timeA = logData[a].time.toDate();
                const timeB = logData[b].time.toDate();
                return timeB - timeA;
              })
              .map((key) => ({ id: key, ...logData[key] }));
            // Iterate over the properties of logData
            Object.keys(sortedKeys).forEach((key) => {
              const log = sortedKeys[key];
              fetchedLogs.push({
                id: log.id,
                username: log.username,
                email: log.email,
                time: TimeAgo(log.time),
                addBy: log.addBy,
              });
            });

            // Trim logs if exceeding 300
            if (fetchedLogs.length >= 300) {
              const logsToDelete = fetchedLogs.slice(-100);
              const batch = [];
              logsToDelete.forEach((log) => {
                const logDocRef = doc(db, "info", "admins", log.id);
                batch.push(
                  updateDoc(logDocRef, {
                    [log.id]: deleteField(),
                  })
                );
              });
              Promise.all(batch);
            }
          } else {
            // No documents found or snapshot is invalid
            setData([]); // Clear existing data
          }

          setData(fetchedLogs);
        });

        return () => unsubscribe(); // Unsubscribe when component unmounts
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchLogs();
  }, [TimeAgo]);

  const handleDelete = async (row) => {
    const isConfirmed = await showConfirmDialog({
      title: "Are you sure?",
      text: `Do you want to remove ${row.username} from admin?`,
      icon: "question",
    });

    if (isConfirmed) {
      try {
        await runTransaction(db, async (transaction) => {
          const userRef = doc(db, "users", row.id);
          transaction.update(userRef, {
            isAdmin: false,
          });

          const emailIdRef = doc(db, "mails", "admins");
          transaction.update(emailIdRef, {
            admins: arrayRemove(row.email),
          });

          const uniqueLogId = generateUid8();
          const logRef = doc(db, "info", "logs");
          transaction.update(logRef, {
            [uniqueLogId]: {
              info: "Removed an admin from admins panel",
              time: serverTimestamp(),
              username: userNickname,
            },
          });
        });

        const docRef = doc(db, "info", "admins");
        await runTransaction(db, async (transaction) => {
          transaction.update(docRef, {
            [row.id]: deleteField(),
          });
        });

        Toast.fire({
          icon: "success",
          title: ` ${row.username} Removed successfully`,
        }).then(() => {
          setLoading(false);
        });
      } catch (error) {
        console.error("Error removing admin:", error);
        Swal.fire(
          "Error!",
          "Failed to remove admin. Please try again.",
          "error"
        ).then(() => {});
      }
    }
  };

  const actions = [{ label: "Remove", onClick: handleDelete }];

  const handleAdd = async () => {
    const isConfirmed = await showConfirmDialog({
      title: "Are you sure?",
      text: `Do you want to add ${name} as admin?`,
      icon: "question",
    });

    if (isConfirmed) {
      setLoading(true);
      try {
        let userRef;
        let userData;

        if (name.includes("@")) {
          // Check if input is an email
          const userEmailDoc = doc(db, "userEmails", name);
          const userEmailSnap = await getDoc(userEmailDoc);
          if (userEmailSnap.exists()) {
            userRef = doc(db, "users", userEmailSnap.data().userId);
            userData = userEmailSnap.data();
          } else {
            Toast.fire({
              icon: "error",
              title: `User with email ${name} does not exist.`,
            });
            setLoading(false);
            return;
          }
        } else {
          // Otherwise, treat as username
          const userNicknameDoc = doc(db, "userNicknames", name);
          const userNicknameSnap = await getDoc(userNicknameDoc);
          if (userNicknameSnap.exists()) {
            userRef = doc(db, "users", userNicknameSnap.data().userId);
            userData = userNicknameSnap.data();
          } else {
            Toast.fire({
              icon: "error",
              title: `User with username ${name} does not exist.`,
            });
            setLoading(false);
            return;
          }
        }

        await runTransaction(db, async (transaction) => {
          transaction.update(userRef, {
            isAdmin: true,
          });

          const emailIdRef = doc(db, "mails", "admins");
          transaction.update(emailIdRef, {
            admins: arrayUnion(userData.userEmail),
          });

          const uniqueLogId = generateUid8();
          const logRef = doc(db, "info", "logs");
          transaction.update(logRef, {
            [uniqueLogId]: {
              info: "Added an admin to admins panel",
              time: serverTimestamp(),
              username: userNickname,
            },
          });

          const adminDetails = {
            username: userData.userName || "",
            userNickname: userData.userNickname || "",
            addBy: userNickname || "",
            email: userData.userEmail || "",
            time: serverTimestamp(),
          };

          const docRef = doc(db, "info", "admins");
          transaction.update(docRef, {
            [userData.userId]: adminDetails,
          });
        });
        setName("");
        Toast.fire({
          icon: "success",
          title: ` ${name} Added successfully`,
        }).then(() => {
          setLoading(false);
        });
      } catch (error) {
        console.error("Error adding admin:", error);
        Swal.fire(
          "Error!",
          "Failed to add admin. Please try again.",
          "error"
        ).then(() => {
          setLoading(false);
        });
      }
    }
  };

  return (
    <div>
      <InputButton
        placeholder={"Enter email or username"}
        className="input theme"
        type={"text"}
        value={name}
        id={"admin"}
        name={"admin"}
        onChange={(e) => setName(e.target.value)}
        ButtonText={loading ? "Adding..." : "Add Admin"}
        onClick={handleAdd}
      />
      <Table data={data} columns={columns} actions={actions} />
    </div>
  );
};

export default ManageAdmin;
