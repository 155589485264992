import { ButtonIcon } from "../../controller/components";
import { StarIcon1, LoveIcon, UserIcon } from "../../data/Data";
import { Toast, useGeneralBrain } from "../../controller/Brain";
import { db } from "../../utils/init-firebase";
import {
  arrayRemove,
  arrayUnion,
  deleteField,
  doc,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import Swal from "sweetalert2";

const ProfileCard = ({ data, setShowProfile, setId, setMyData }) => {
  const { who, userData, generateUid8, userNickname } = useGeneralBrain();
  const isShortlistView = window.location.pathname.includes(
    "/matrimony/view/shortlist"
  );

  const handleShortlist = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const liveShortlistIdsRef = doc(
          db,
          "liveShortlistIds",
          userData?.userId
        );
        
        transaction.update(liveShortlistIdsRef, {
          ids: arrayUnion(data.d106),
        });
      });
      setMyData((prevData) => [...prevData, data.d106]);
      Toast.fire({
        icon: "success",
        title: ` ${data.d101} Shortlisted successfully`,
      }).then(() => {});
    } catch (error) {
      console.error("Error", error);
      Swal.fire("Error!", " Please try again.", "error").then(() => {});
    }
  };

  const handleRemove = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const liveShortlistIdsRef = doc(
          db,
          "liveShortlistIds",
          userData?.userId
        );
        transaction.update(liveShortlistIdsRef, {
          ids: arrayRemove(data.d106),
        });
      });

      Toast.fire({
        icon: "success",
        title: ` ${data.d101} Removed successfully`,
      }).then(() => {});
    } catch (error) {
      console.error("Error", error);
      Swal.fire("Error!", " Please try again.", "error").then(() => {});
    }
  };

  const handleViewProfile = () => {
    setId(data.d106);
    setShowProfile(true);
    window.scrollTo(0, 0);
  };

  const handleSuspend = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const status = "d107";
        const live1 = data.d108 === "Male" ? "livemale1" : "livefemale1";
        const liveMatrimonysRef = doc(db, "liveMatrimonys", live1);
        transaction.update(liveMatrimonysRef, {
          [`${data.d106}.${status}`]: "suspended",
        });

        const uniqueLogId = generateUid8();
        const logRef = doc(db, "info", "logs");
        transaction.update(logRef, {
          [uniqueLogId]: {
            info: `Suspended ${data.d101} matrimony profile`,
            time: serverTimestamp(),
            username: userNickname,
          },
        });
      });

      Toast.fire({
        icon: "success",
        title: ` ${data.d101} Suspended successfully`,
      }).then(() => {});
    } catch (error) {
      console.error("Error", error);
      Swal.fire("Error!", " Please try again.", "error").then(() => {});
    }
  };

  const handleUnSuspend = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const status = "d107";
        const live1 = data.d108 === "Male" ? "livemale1" : "livefemale1";
        const liveMatrimonysRef = doc(db, "liveMatrimonys", live1);
        transaction.update(liveMatrimonysRef, {
          [`${data.d106}.${status}`]: "approved",
        });

        const uniqueLogId = generateUid8();
        const logRef = doc(db, "info", "logs");
        transaction.update(logRef, {
          [uniqueLogId]: {
            info: `Unsuspended ${data.d101} matrimony profile`,
            time: serverTimestamp(),
            username: userNickname,
          },
        });
      });

      Toast.fire({
        icon: "success",
        title: ` ${data.d101} Approved successfully`,
      }).then(() => {});
    } catch (error) {
      console.error("Error", error);
      Swal.fire("Error!", " Please try again.", "error").then(() => {});
    }
  };

  const handleDelete = async () => {
    try {
      await runTransaction(db, async (transaction) => {
        const live1 = data.d108 === "Male" ? "livemale1" : "livefemale1";
        const liveMatrimonysRef = doc(db, "liveMatrimonys", live1);
        transaction.update(liveMatrimonysRef, {
          [data.d106]: deleteField(),
        });

        const liveShortlistIdsRef = doc(db, "liveShortlistIds", data.d106);
        transaction.delete(liveShortlistIdsRef);

        const liveShortlistsRef = doc(db, "liveShortlists", data.d106);
        transaction.delete(liveShortlistsRef);

        const matrimonysRef = doc(db, "matrimonys", data.d106);
        transaction.delete(matrimonysRef);

        const userRef = doc(db, "users", data.d106);
        transaction.update(userRef, {
          isSubcribed: false,
          isSubcribedStatus: "deleted",
        });

        const uniqueLogId = generateUid8();
        const logRef = doc(db, "info", "logs");
        transaction.update(logRef, {
          [uniqueLogId]: {
            info: `Deleted ${data.d101} matrimony profile`,
            time: serverTimestamp(),
            username: userNickname,
          },
        });
      });

      Toast.fire({
        icon: "success",
        title: ` ${data.d101} Deleted successfully`,
      }).then(() => {});
    } catch (error) {
      console.error("Error", error);
      Swal.fire("Error!", " Please try again.", "error").then(() => {});
    }
  };

  return (
    <div className="profile-card box-shadow">
      <span className="profile-star-icon">
        <StarIcon1 />
      </span>
      <div className="profile-card-details"> 
        <img src={data.d105} alt="img" className="profile-imag" />
        <div className="profile-info-1">
          <div className="profile-flex-info">
            <span className="profile-title">Name:</span>
            <span className="profile-subtitle">{data.d101}</span>
          </div>
          <div className="profile-flex-info">
            <span className="profile-title">Age:</span>
            <span className="subtitle">{data.d102}</span>
          </div>
          <div className="profile-flex-info">
            <span className="profile-title">Occupation:</span>
            <span className="subtitle">{data.d103}</span>
          </div>
          <div className="profile-flex-info">
            <span className="profile-title">Location:</span>
            <span className="subtitle">{data.d104}</span>
          </div>
        </div>
        <div className="profile-flex">
          <ButtonIcon
            rounded={true}
            text={
              who === "matrimony"
                ? "View Profile"
                : who === "admin"
                ? `${data.d107 === "suspended" ? "Unsuspend" : "Suspend"}`
                : ""
            }
            icon={UserIcon}
            onClick={
              who === "matrimony"
                ? handleViewProfile
                : who === "admin"
                ? data.d107 === "suspended"
                  ? handleUnSuspend
                  : handleSuspend
                : null
            }
          />
          <ButtonIcon
            rounded={true}
            text={
              who === "matrimony"
                ? `${isShortlistView ? "Remove" : "Shortlist"}`
                : who === "admin"
                ? "Delete"
                : ""
            }
            icon={LoveIcon}
            onClick={
              who === "matrimony"
                ? isShortlistView
                  ? handleRemove
                  : handleShortlist
                : who === "admin"
                ? handleDelete
                : undefined
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
