import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  serverTimestamp,
  runTransaction,
  collection,
  getDoc,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { Toast, useGeneralBrain } from "../../controller/Brain";
import { useAuth } from "../../contexts/AuthContext";

const ApproveUser = () => {
  const { query } = useParams();
  const { userData } = useAuth();
  const { generateUid8 } = useGeneralBrain();
  const [userId, nickname, email] = query.split("*").slice(-3);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const checkApprovalStatus = async () => {
      setLoading1(true);
      try {
        const ARef = doc(db, "users", userId);
        const DocSnap = await getDoc(ARef);
        if (DocSnap.exists()) {
          const aData = DocSnap.data();
          if (aData?.isMember) {
            setIsApproved(true);
          }
        }
      } catch (error) {
        console.error("Error checking approval status:", error);
      } finally {
        setLoading1(false);
      }
    };

    checkApprovalStatus();
  }, [userId]);

  const handleApprove = async () => {
    if (!userData?.isAdmin) {
      Toast.fire({
        title: "Insufficient Permission",
        text: "You do not have permission to approve users.",
        icon: "error",
      });
      return;
    }

    if (isApproved) {
      Toast.fire({
        title: "User Approved",
        text: "Action has already been taken for this user.",
        icon: "error",
      });
      return;
    }

    setLoading(true);

    try {
      await runTransaction(db, async (transaction) => {
        const userRef = doc(db, "users", userId);
        transaction.update(userRef, { isMember: true });

        const code = "code";
        const dociRef = doc(db, "info", "users");
        transaction.update(dociRef, {
          [`${userId}.${code}`]: 1100000,
        });

        const emailRef = collection(db, "sendEmails");
        const docRef = doc(emailRef);
        transaction.set(docRef, {
          recipient: email,
          subject: "Congratulation",
          title: `Hi ${nickname}`,
          content: `Your nevewanisamaj account has been approved`,
          link: "nevewanisamaj.in",
          timestamp: serverTimestamp(),
        });

        // Log the approval action
        const uniqueLogId = generateUid8();
        const logRef = doc(db, "info", "logs");
        transaction.update(logRef, {
          [uniqueLogId]: {
            info: `An admin just approved a user with email ${email}`,
            time: serverTimestamp(),
            username: userData?.userNickname,
          },
        });
      });
      Toast.fire({
        title: "Success!",
        text: "User approved successfully.",
        icon: "success",
      });
      setIsApproved(true);
    } catch (error) {
      console.error("Error approving user:", error);
      Toast.fire({
        title: "Error",
        text: "Failed to approve user.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading1) {
    return <div>Loading...</div>;
  }

  return (
    <div className="form-container">
      <div className="form box-shadow">
        {isApproved ? (
          <div>Link expired! Action has already been taken for this user. {nickname} is a member </div>
        ) : (
          <div className="confirm-container">
            <h2 className="confirm-title">Approve User</h2>
            <div className="">
              <p className="confirm-text">
                Click approve user to approve. And send an email to let them
                know they have been approved.
              </p>
            </div>
            <div>
              <span
                onClick={handleApprove}
                className="confirm-button"
                style={{ cursor: loading ? "not-allowed" : "pointer" }}
              >
                {loading ? "Approving user..." : "Approve user"}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApproveUser;
