import Table from "../../components/cards/Table";
import { Toast } from "../../controller/Brain";
// import SearchEngine from "../../controller/SearchEngine";
import React, { useEffect, useState } from "react";
import { db } from "../../utils/init-firebase";
import {
  doc,
  updateDoc,
  deleteField,
  runTransaction,
  serverTimestamp,
  onSnapshot,
  collection,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import Swal from "sweetalert2";
import { showConfirmDialog, useGeneralBrain } from "../../controller/Brain";
import { useAuth } from "../../contexts/AuthContext";

const ManageUser = () => {
  const {
    userNickname,
    generateUid8,
    TimeAgo,
    calculateAge,
    StatusCode,
    userData,
  } = useGeneralBrain();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { deleteAdminUserAccount, DeleteMatrimonyProfile } = useAuth();

  const columns = ["ID", "Name", "Email", "Phone Number", "Status"];

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const docRef = doc(db, "info", "users");
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
          const fetchedLogs = [];
          if (snapshot.exists()) {
            const logData = snapshot.data();

            const sortedKeys = Object.keys(logData)
              .sort((a, b) => {
                const timeA = logData[a].time.toDate();
                const timeB = logData[b].time.toDate();
                return timeB - timeA;
              })
              .map((key) => ({ id: key, ...logData[key] }));

            Object.keys(sortedKeys).forEach((key) => {
              const log = sortedKeys[key];
              fetchedLogs.push({
                id: log.id,
                username: log.name,
                email: log.email,
                number: log.number || "N/A",
                status: StatusCode(log.code),
              });
            });

            // Trim logs if exceeding 300
            if (fetchedLogs.length >= 300) {
              const logsToDelete = fetchedLogs.slice(-100);
              const batch = [];
              logsToDelete.forEach((log) => {
                const logDocRef = doc(db, "info", "admins", log.id);
                batch.push(
                  updateDoc(logDocRef, {
                    [log.id]: deleteField(),
                  })
                );
              });
              Promise.all(batch);
            }
          } else {
            setData([]);
          }

          setData(fetchedLogs);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchLogs();
  }, [TimeAgo, StatusCode]);

  const handleDeleteMatrimony = async (row) => {
    if (loading) {
      return;
    }

    if (row.status !== "Approved") {
      Toast.fire({
        title: "Error!",
        text: "Cannot perform  action!",
        icon: "error",
      });
      return;
    }

    await DeleteMatrimonyProfile (row.id);
  };

  const handleDeleteUser = async (row) => {
    if (loading) {
      return;
    }
    await deleteAdminUserAccount(row.id);
  };

  const handleApproveMatrimony = async (row) => {

    if (loading) {
      return;
    }

    if (row.status === "Approved") {
      Toast.fire({
        title: "Error!",
        text: "User is already approved.",
        icon: "error",
      });
      return;
    }

    if (row.status !== "Paid") {
      Toast.fire({
        title: "Error!",
        text: "Cannot perform  action!",
        icon: "error",
      });
      return;
    }

    const isConfirmed = await showConfirmDialog({
      title: "Are you sure?",
      text: `Do you want to approve ${row.username} matrimony profiles?`,
      icon: "question",
    });

    if (isConfirmed) {
      setLoading(true);

      const matrimonyRef = doc(db, "matrimonys", row.id);
      const DocSnap = await getDoc(matrimonyRef);
      if (!DocSnap.exists()) {
        throw new Error("No document");
      }

      const matriData = DocSnap.data();

      try {
        await runTransaction(db, async (transaction) => {
          const userRef = doc(db, "users", row.id);
          transaction.update(userRef, {
            isSubcribed: true,
          });

          const shortlistsRef = doc(db, "liveShortlists", row.id);
          transaction.set(shortlistsRef, {});

          const liveShortlistIdsRef = doc(db, "liveShortlistIds", row.id);
          transaction.set(liveShortlistIdsRef, {});

          const matrimonyRef = doc(db, "matrimonys", row.id);
          transaction.update(matrimonyRef, {
            status: "approved",
          });

          const code = "code";
          const dociRef = doc(db, "info", "users");
          transaction.update(dociRef, {
            [`${row.id}.${code}`]: 1111000,
          });

          const emailIdRef = doc(db, "mails", "matrimonys");
          transaction.update(emailIdRef, {
            admins: arrayUnion(row.email),
          });

          const live1 =
            matriData.gender === "Male" ? "livemale1" : "livefemale1";
          const liveMatrimonysRef = doc(db, "liveMatrimonys", live1);
          transaction.update(liveMatrimonysRef, {
            [row.id]: {
              d101: matriData.name,
              d102: calculateAge(matriData.birthDate),
              d103: matriData.job,
              d104: matriData.location,
              d105: matriData.imgURLs[0],
              d106: row.id,
              d107: "approved",
              d108: matriData.gender,
            },
          });

          const uniqueLogId = generateUid8();
          const logRef = doc(db, "info", "logs");
          transaction.update(logRef, {
            [uniqueLogId]: {
              info: `Approved ${row.username} matrimony profile`,
              time: serverTimestamp(),
              username: userNickname,
            },
          });

          const emailRef = collection(db, "sendEmails");
          const docRef = doc(emailRef);
          transaction.set(docRef, {
            recipient: row.email,
            subject: "Congratulation",
            title: `Hi ${row.username}`,
            content: `Your Matrimony profile has been approved!`,
            link: "nevewanisamaj.in",
            timestamp: serverTimestamp(),
          });
        });

        Toast.fire({
          icon: "success",
          title: ` ${row.username} Approved successfully`,
        }).then(() => {
          setLoading(false);
        });
      } catch (error) {
        console.error("Error", error);
        Swal.fire("Error!", " Please try again.", "error").then(() => {
          setLoading(false);
        });
      }
    }
  };

  const handleApproveUser = async (row) => {
    if (loading) {
      return;
    }

    if (row.status === "Member") {
      Toast.fire({
        title: "Error!",
        text: "User is already a member.",
        icon: "error",
      });
      return;
    }

    if (row.status !== "Incoming") {
      Toast.fire({
        title: "Error!",
        text: "Cannot perform action",
        icon: "error",
      });
      return;
    }

    const isConfirmed = await showConfirmDialog({
      title: "Are you sure?",
      text: `Do you want to approve ${row.username} as an user?`,
      icon: "question",
    });

    if (isConfirmed) {
      setLoading(true);
      try {
        await runTransaction(db, async (transaction) => {
          const userRef = doc(db, "users", row.id);
          transaction.update(userRef, { isMember: true });

          const code = "code";
          const dociRef = doc(db, "info", "users");
          transaction.update(dociRef, {
            [`${row.id}.${code}`]: 1100000,
          });

          const emailRef = collection(db, "sendEmails");
          const docRef = doc(emailRef);
          transaction.set(docRef, {
            recipient: row.email,
            subject: "Congratulation",
            title: `Hi ${row.username}`,
            content: `Your account has been approved`,
            link: "nevewanisamaj.in",
            timestamp: serverTimestamp(),
          });

          // Log the approval action
          const uniqueLogId = generateUid8();
          const logRef = doc(db, "info", "logs");
          transaction.update(logRef, {
            [uniqueLogId]: {
              info: `An admin just approved a user with email ${row.email}`,
              time: serverTimestamp(),
              username: userData?.userNickname,
            },
          });
        });
        Toast.fire({
          title: "Success!",
          text: "User approved successfully.",
          icon: "success",
        });
      } catch (error) {
        console.error("Error approving user:", error);
        Toast.fire({
          title: "Error",
          text: "Failed to approve user.",
          icon: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const actions = [
    { label: `${loading ? "Wait..." : "+ User"}`, onClick: handleApproveUser },
    { label: `${loading ? "Wait..." : "- User"}`, onClick: handleDeleteUser },
    {
      label: `${loading ? "Wait..." : "+ Matrimony"}`,
      onClick: handleApproveMatrimony,
    },
    {
      label: `${loading ? "Wait..." : "- Matrimony"}`,
      onClick: handleDeleteMatrimony,
    },
  ];

  return (
    <div>
      <div className="manage-search">{/* <SearchEngine /> */}</div>
      <Table data={data} columns={columns} actions={actions} />
    </div>
  );
};

export default ManageUser;
