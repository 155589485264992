

import React, { useState } from 'react';

const CookieConsent = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    localStorage.setItem('cookieConsent', 'accepted');
  };

  const isAccepted = localStorage.getItem('cookieConsent') === 'accepted';

  if (isAccepted || accepted) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <p>Nevewanisamaj.in uses cookies to ensure you get the best experience.</p>
      <button onClick={handleAccept}>Accept</button>
    </div>
  );
};

export default CookieConsent;