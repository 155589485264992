import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { showConfirmDialog, useGeneralBrain } from "../../controller/Brain";
import { storage, db } from "../../utils/init-firebase";
import {
  doc,
  getDoc,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const ManageInfo = () => {
  const { userNickname, generateUid8 } = useGeneralBrain();
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState("");
  const [amount, setAmount] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "info", "adminmanageInfo");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setQrCodeImageUrl(data.qrCodeImage || "");
          setAmount(data.amount || "");
          setContactNumber(data.contactNumber || "");
          setContactEmail(data.contactEmail || "");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "qrCodeImage" && files[0]) {
      setQrCodeImage(files[0]);
      setQrCodeImageUrl(URL.createObjectURL(files[0]));
    } else if (name === "amount") {
      setAmount(value);
    } else if (name === "contactNumber") {
      setContactNumber(value);
    } else if (name === "contactEmail") {
      setContactEmail(value);
    }
  };

  const handleSave = async () => {
    const isConfirmed = await showConfirmDialog({
      title: "Are you sure?",
      text: "Do you want to save these details?",
      icon: "question",
    });
  
    if (isConfirmed) {
      setLoading(true);
  
      try {
        let imgURL = qrCodeImageUrl;
        if (qrCodeImage) {
          const storageRef = ref(storage, `info/adminmanageInfo/paymentImg`);
          const uploadTask = uploadBytesResumable(storageRef, qrCodeImage);
          await Promise.all([uploadTask]);
          imgURL = await getDownloadURL(storageRef);
        }
  
        const uniqueLogId = generateUid8();
  
        await runTransaction(db, async (transaction) => {
          const docRef = doc(db, "info", "adminmanageInfo");
          transaction.update(docRef, {
            qrCodeImage: imgURL,
            amount: amount,
            contactNumber: contactNumber,
            contactEmail: contactEmail,
          });
  
          const logRef = doc(db, "info", "logs");
          transaction.update(logRef, {
            [uniqueLogId]: {
              info: "Update details for payment, price and contacts",
              time: serverTimestamp(),
              username: userNickname,
            },
          });
        });
        Swal.fire("Success!", "Details saved successfully.", "success").then(
          () => {
            setLoading(false);
          }
        );
      } catch (error) {
        console.error("Error saving payment details: ", error);
        Swal.fire(
          "Error!",
          "Failed to save payment details. Please try again.",
          "error"
        ).then(() => {
          setLoading(false);
        });
      }
    }
  };
  

  return (
    <div className="flex-center">
      <div className="form">
        <h2>Manage Information</h2>
        <div className="manage-info-input">
          <label>Payment QR Code Image:</label>
          <input
            type="file"
            name="qrCodeImage"
            accept="image/*"
            onChange={handleChange}
          />
          {qrCodeImageUrl && (
            <img
              src={qrCodeImageUrl}
              alt="QR Code"
              style={{ maxWidth: "200px", marginTop: "10px" }}
            />
          )}
        </div>
        <div className="manage-info-input">
          <label>Payment Amount:</label>
          <input
            type="text"
            name="amount"
            value={amount}
            onChange={handleChange}
          />
        </div>
        <div className="manage-info-input">
          <label>Contact Number:</label>
          <input
            type="text"
            name="contactNumber"
            value={contactNumber}
            onChange={handleChange}
          />
        </div>
        <div className="manage-info-input">
          <label>Contact Email:</label>
          <input
            type="email"
            name="contactEmail"
            value={contactEmail}
            onChange={handleChange}
          />
        </div>
        <button
          className="manage-info-button"
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? "Uploading..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default ManageInfo;
