import React, { useEffect, useState } from "react";
import MangeLink from "../components/cards/MangeLink";
import StepperForm from "../components/cards/StepperForm";
import { useGeneralBrain } from "../controller/Brain";
import { db } from "../utils/init-firebase";
import { doc, getDoc } from "firebase/firestore";

const Home = () => {
  const { userData } = useGeneralBrain();
  const [showForm, setShowForm] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    if (!userData?.isSubcribed) {
      const fetchData = async () => {
        try {
          const docRef = doc(db, "info", "adminmanageInfo");
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setData(data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [userData?.isSubcribed]);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleBack = () => {
    setShowForm(false);
  };

  return (
    <div className="flex-center">
      <div className="form">
        {!showForm && (
          <div className="components">
            {userData?.isSubcribed ? (
              <MangeLink
                url={"/matrimony"}
                title={"Matrimony"}
                text={"View Profiles"}
              />
            ) : (
              <div>
                {userData?.isSubcribedStatus === "" ? (
                  <MangeLink
                    title={"Matrimony"}
                    text={`Want access to our matrimony channel? Get in with a one time fee of: RS.${data?.amount} `}
                    onClick={handleShowForm}
                  />
                ) : userData?.isSubcribedStatus === "under review" ? (
                  <MangeLink
                    title={"Matrimony"}
                    text={`Your payment is under review! kindly wait for approval`}
                  />
                ) : userData?.isSubcribedStatus === "rejected" ? (
                  <MangeLink
                    title={"Matrimony"}
                    text={`You Payment cannot be confirmed! Try again to pay RS.${data?.amount} to get access`}
                    onClick={handleShowForm}
                  />
                ) : userData?.isSubcribedStatus === "approved" ? (
                  <MangeLink
                    title={"Matrimony"}
                    text={`Your Payment has been approved! Register your matrimony profile.`}
                    onClick={handleShowForm}
                  />
                ) : userData?.isSubcribedStatus === "deleted" ? (
                  <MangeLink
                    title={"Matrimony"}
                    text={`Your Matrimony profile have been deleted, Please contact support for help.`}
                    onClick={handleShowForm}
                  />
                ) : (
                  <MangeLink
                    title={"Matrimony"}
                    text={
                      "Your matrimony profile is under review wait for approval!"
                    }
                  />
                )}
              </div>
            )}
          </div>
        )}

        {showForm && (
          <div>
            <div className="block-back">
              <div onClick={handleBack} className="all-back-button">
                Back
              </div>
            </div>
            <StepperForm data={data} setShowForm={setShowForm} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
