import React from "react";

const Board = ({ text, title }) => {
  return (
    <div className="board-card">
      <h2 className="board-title">{title}</h2>
      <div className="board-text">{text}</div>
    </div> 
  );
};

export default Board;
