import React from "react";
import { Link } from "react-router-dom";

const ForgetMail = () => {
  return (
    <div className="form-container">
      <div className="form box-shadow">
        <div className="confirm-container">
          <h2 className="confirm-title">Email sent!</h2>
          <div className="">
            <p className="confirm-text">
              We have recieved your request and sent you a link to reset your
              password!.
            </p>
            <p className="confirm-text">
              If you don't see the email in your inbox, please check your spam
              folder.
            </p>
          </div>
          <Link className="confirm-button" to={"/help"}>
            Help
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgetMail;
