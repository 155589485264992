import React, { useState, useEffect } from "react";
import Table from "../../components/cards/Table";
import { db } from "../../utils/init-firebase";
import {
  doc,
  getDoc,
  updateDoc,
  deleteField,
  runTransaction,
} from "firebase/firestore";
import { Toast, useGeneralBrain } from "../../controller/Brain";
import NothingYet from "../../components/general/NothingYet";
import Swal from "sweetalert2";
import PageWait from "../../components/general/PageWait";

const ViewLogs = () => {
  const { TimeAgo } = useGeneralBrain();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const columns = ["Index", "Information", "Time", "Username"];

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const docRef = doc(db, "info", "logs");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const fetchedLogs = [];
          const logData = docSnap.data();
          const sortedKeys = Object.keys(logData)
            .sort((a, b) => {
              const timeA = logData[a].time.toDate();
              const timeB = logData[b].time.toDate();
              return timeB - timeA;
            })
            .map((key) => ({ id: key, ...logData[key] }));

          Object.keys(sortedKeys).forEach((key) => {
            const log = sortedKeys[key];
            fetchedLogs.push({
              id: key,
              info: log.info,
              time: TimeAgo(log.time),
              username: log.username,
            });
          });

          if (fetchedLogs.length >= 300) {
            const logsToDelete = fetchedLogs.slice(200);
            const batch = [];
            logsToDelete.forEach((log) => {
              const logDocRef = doc(db, "info", "logs");
              batch.push(
                updateDoc(logDocRef, {
                  [log.id]: deleteField(),
                })
              );
            });
            await Promise.all(batch);
          }
          setLogs(fetchedLogs);
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [TimeAgo]);

  const clearAllLogs = async () => {
    setLoading1(true);
    try {
      await runTransaction(db, async (transaction) => {
        const docRef = doc(db, "info", "logs");
        transaction.set(docRef, {});
      });

      setLogs([]);
      setLoading1(false);
      Toast.fire("Success!", "All logs have been cleared.", "success");
    } catch (error) {
      console.error("Error clearing logs:", error);
      Swal.fire("Error!", "Failed to clear logs. Please try again.", "error");
    }
  };

  if (loading) {
    return <PageWait />;
  }

  return (
    <div>
      {logs.length === 0 ? (
        <NothingYet />
      ) : (
        <div>
          <div onClick={clearAllLogs} className="log-button">
            {loading1 ? "Clearing logs ..." : "Clear All Logs"}
          </div>
          <Table data={logs} columns={columns} />
        </div>
      )}
    </div>
  );
};

export default ViewLogs;
