import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Container from "../controller/Container";
import Home from "../pages/Overview";
import About from "../pages/About";
import NotFound from "../pages/NotFound";
import Help from "../pages/Help";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ForgotPassword";
import Settings from "../pages/Settings";
import Matrimony from "../pages/user/Matrimony";
import EditProfile from "../pages/user/ManageProfile";
import ViewProfile from "../pages/user/ViewProfile";
import ViewShortlist from "../pages/user/ViewShortlist";
import Overview from "../pages/admin/Overview";
import ManageAdmin from "../pages/admin/ManageAdmin";
import ManageUser from "../pages/admin/ManageUser";
import ManageInfo from "../pages/admin/ManageInfo";
import ManageMatrimony from "../pages/admin/ManageMatrimony";
import ViewLogs from "../pages/admin/ViewLogs";
import {
  ProtectedRoutes,
  AuthPass,
  AdminAuth,
  MatrimonyAuth,
} from "../utils/ProtectedRoute";
import ApproveUser from "./cards/ApproveUser";
import ApproveMatrimony from "./cards/ApproveMatrimony";

export default function AppRouter() {

  return (
    <Router>
      <Routes>
        <Route
          path="/about"
          element={
            <Container
              card={About}
              path={"home"}
              title={"About"}
              board={"About us"}
            />
          }
        />
        <Route
          path="/approve-user/:query"
          element={
            <Container
              card={ApproveUser}
              path={"home"}
              title={"Approve User"}
            />
          }
        />
        <Route
          path="/approve-matrimony/:query"
          element={
            <Container
              card={ ApproveMatrimony}
              path={"home"}
              title={"Approve User"}
            />
          }
        />
        <Route
          path="/help"
          element={
            <Container
              card={Help}
              path={"home"}
              title={"Help"}
              board={"Help"}
            />
          }
        />

        <Route element={<ProtectedRoutes />}>
          <Route
            path="/login"
            element={<Container card={Login} path={"home"} title={"Login"} />}
          />
          <Route
            path="/register"
            element={
              <Container card={Register} path={"home"} title={"Register"} />
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Container
                card={ForgotPassword}
                path={"home"}
                title={"Forgot Password"}
              />
            }
          />

          <Route
            path="/*"
            element={
              <Container
                card={NotFound}
                path={"home"}
                title={"Not Found Page"}
                board={"Not Found Page"}
              />
            }
          />
        </Route>

        <Route element={<AuthPass />}>
          <Route
            path="/"
            element={
              <Container
                card={Home}
                path={"user"}
                title={"Overview"}
                board={"Welcome to Neve Wani Samaj Vikas Pratishthan"}
              />
            }
          />
          <Route
            path="/settings"
            element={
              <Container
                card={Settings}
                path={"user"}
                title={"Settings"}
                board={"Settings"}
              />
            }
          />
        </Route>
        <Route element={<MatrimonyAuth />}>
          <Route
            path="/matrimony"
            element={
              <Container
                card={Matrimony}
                path={"matrimony"}
                title={"Matrimony"}
                board={"Welcome To Matrimony Channel"}
              />
            }
          />
          <Route
            path="/matrimony/manage/profile"
            element={
              <Container
                card={EditProfile}
                path={"matrimony"}
                title={"Manage Profile"}
                board={"Manage Profile"}
              />
            }
          />
          <Route
            path="/matrimony/view/profile"
            element={
              <Container
                card={ViewProfile}
                path={"matrimony"}
                title={"View Profile"}
                board={"View Profile"}
              />
            }
          />
          <Route
            path="/matrimony/view/shortlist"
            element={
              <Container
                card={ViewShortlist}
                path={"matrimony"}
                title={"View Shortlist"}
                board={"View Shortlist"}
              />
            }
          />
        </Route>

        <Route element={<AdminAuth />}>
          <Route
            path="/admin/overview"
            element={
              <Container
                card={Overview}
                path={"admin"}
                title={"Overview"}
                board={"Admin Panel"}
              />
            }
          />
          <Route
            path="/admin/manage/admin"
            element={
              <Container
                card={ManageAdmin}
                path={"admin"}
                title={"Manage Admin"}
                board={"Manage Admin"}
              />
            }
          />
          <Route
            path="/admin/manage/user"
            element={
              <Container
                card={ManageUser}
                path={"admin"}
                title={"Manage User"}
                board={"Manage User"}
              />
            }
          />

          <Route
            path="/admin/manage/matrimony"
            element={
              <Container
                card={ManageMatrimony}
                path={"admin"}
                title={"Manage Matrimony"}
                board={"Manage Matrimony"}
              />
            }
          />
          <Route
            path="/admin/manage/info"
            element={
              <Container
                card={ManageInfo}
                path={"admin"}
                title={"Overview"}
                board={"Manage Info"}
              />
            }
          />
          <Route
            path="/admin/view/logs"
            element={
              <Container
                card={ViewLogs}
                path={"admin"}
                title={"View Logs"}
                board={"View Logs"}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}
