import React, { useState } from "react";
import { EmailSvg } from "../data/Data";
import { Input } from "../controller/components";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import ForgetMail from "../components/cards/ForgetMail";
import { Toast } from "../controller/Brain";

const ForgotPassword = () => {
  const { handleForgotPassword } = useAuth();
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      Toast.fire({
        title: "Error",
        text: "Email is needed.",
        icon: "error",
      });
      return;
    }

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Toast.fire({
        title: "Error",
        text: "Please enter a valid email address.",
        icon: "error",
      });
      return;
    }

    setLoading(true);
    await handleForgotPassword(email);
    setLoading(false);
    setFormSubmitted(true);
  };

  return (
    <>
      {!formSubmitted && (
        <div className="form-container">
          <div>
            <form className="form">
              <div className="form-title">Forgot Password</div>
              <Input
                title="Email"
                svg={EmailSvg}
                placeholder="Enter your Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button onClick={handleSubmit} className="button-submit">
                {loading ? "Loading..." : "Reset Password"}
              </button>
              <p className="p">
                Don't have an account?{" "}
                <Link to={"/register"} className="span">
                  Sign Up
                </Link>
              </p>
            </form>
          </div>
        </div>
      )}

      {formSubmitted && <ForgetMail />}
    </>
  );
};

export default ForgotPassword;
