import React from "react";
import { Nothing } from "../../data/Data";

const NothingYet = ({ text }) => {
  return (
    <div className="allsvgclass">
      <h3>EMPTY</h3>
      <Nothing />
      <h5>{text || "Nothing here"}</h5>
    </div>
  );
};

export default NothingYet;
