import React from 'react'
import { SearchIcon } from '../data/Data'

const SearchEngine = ({ onSearch, setSortBy }) => {
  const handleSearchChange = (e) => {
    const searchText = e.target.value;
    onSearch(searchText);

    // Check if the search text starts with a number
    const startsWithNumber = /^\d/.test(searchText);
    setSortBy(startsWithNumber ? "age" : "name");
  };

  return (
    <div className='search-engine-container'>
      <input
        placeholder={"Search with name or age..."}
        className="input-search button-rounded theme"
        type={"text"}
        onChange={handleSearchChange}
      />
      <div className='search-icon-theme'><SearchIcon /></div>
    </div>
  )
}

export default SearchEngine
