import React, { useState } from "react";
import MangeLink from "../components/cards/MangeLink";
import { useGeneralBrain } from "../controller/Brain";
import { useAuth } from "../contexts/AuthContext";
import ChangePassword from "../components/cards/ChangePassword";

const Settings = () => {
  const { userData } = useGeneralBrain();
  const { deleteUserAccount, DeleteMatrimonyProfile, UpdatePassword } =
    useAuth();
  const [show, setshow] = useState(false);

  return (
    <div className="flex-center">
      {show && (
        <ChangePassword UpdatePassword={UpdatePassword} setshow={setshow} />
      )}

      {!show && (
        <div className="form">
          <MangeLink
            title={"Change Password"}
            text={"Change your password"}
            onClick={() => setshow(true)}
          />
          <MangeLink
            title={"Delete Account"}
            text={"This process cannot be undone"}
            onClick={() => deleteUserAccount(userData.userId)}
          />
          {userData?.isSubcribed && (
            <MangeLink
              title={"Delete Matrimony Profile"}
              text={"This process cannot be undone"}
              onClick={() => DeleteMatrimonyProfile(userData.userId)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Settings;
