import React from 'react'
import Profile from '../../components/cards/Profile'
import { useNavigate } from 'react-router-dom';

const EditProfile = () => { 
  const navigate = useNavigate(); 

  const handleViewProfile = () => {
    navigate(`/matrimony`);
  };

  return ( 
    <div><Profile onClick={handleViewProfile}/></div>
  )
}

export default EditProfile