import React from "react";
import Contact from "../components/general/Contact";

const About = () => {
  return (
    <div className="flex-center">
      <div className="form">
        <h1>About Us</h1>
        <p>Welcome to NWSVP Matrimony!</p>
        <p>
          NWSVP Matrimony is a project by Neve Wani Samaj Vikas Pratishthan. We
          are a trusted and reliable platform dedicated to helping people find
          their life partners. Our mission is to make the journey of finding a
          life partner easy, enjoyable, and successful.
        </p>

        <h2>Our Mission</h2>
        <p>
          At NWSVP Matrimony, our mission is to provide a reliable, secure, and
          efficient platform for individuals seeking life partners. We are
          committed to helping our members find compatible matches, build strong
          relationships, and embark on their journey of marital bliss.
        </p>

        <h2>Our Vision</h2>
        <p>
          We envision a society where every individual finds their perfect life
          partner, leading to fulfilling and happy marriages. We aim to be the
          most trusted and preferred matrimony platform, offering innovative
          features, exceptional customer service, and a seamless user
          experience.
        </p>

        <h2>What Sets Us Apart</h2>
        <p>
          At NWSVP Matrimony, we understand that finding the right life partner
          is a significant and deeply personal decision. Therefore, we
          prioritize the privacy, security, and satisfaction of our members.
        </p>
        <ul>
          <li>
            <strong>Privacy:</strong> We respect the privacy of our members and
            ensure the confidentiality of their personal information.
          </li>
          <li>
            <strong>Security:</strong> Our platform is secure, with robust
            measures in place to safeguard against unauthorized access and
            misuse of data.
          </li>
          <li>
            <strong>Verified Profiles:</strong> We verify the authenticity of
            every profile to ensure genuine and reliable matches.
          </li>
          <li>
            <strong>Exceptional Customer Service:</strong> Our dedicated
            customer support team is available to assist our members at every
            step of their journey.
          </li>
          <li>
            <strong>Advanced Search Features:</strong> Our advanced search
            filters help members find matches based on their preferences, making
            the process efficient and effective.
          </li>
          <li>
            <strong>User-Friendly Interface:</strong> Our user-friendly
            interface makes navigating the platform easy and enjoyable, ensuring
            a seamless user experience.
          </li>
        </ul>

        <Contact />
      </div>
    </div>
  );
};

export default About;
