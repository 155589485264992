import React, { useState } from "react";
import {
  EmailSvg,
  PasswordSvg,
  PhoneSvg,
  ProjectName,
  UserSvg,
} from "../data/Data";
import { HomeButton, Input } from "../controller/components";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Toast } from "../controller/Brain";
import { db } from "../utils/init-firebase";
import { doc, getDoc } from "firebase/firestore";
import NotMember from "../components/cards/NotMember";


const Register = () => {
  const { register } = useAuth();
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [nickname, setNickname] = useState("");
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [loading, setloading] = useState(false);
  const [isMember, setIsMember] = useState(false);

  const checkNicknameExists = async (nickname) => {
    const userNicknamesRef = doc(db, "userNicknames", `${nickname}`);
    const docSnapshot = await getDoc(userNicknamesRef);
    return docSnapshot.exists();
  };

  const checkEmailExists = async (registerEmail) => {
    const userregisterEmailRef = doc(db, "userEmails", `${registerEmail}`);
    const docSnapshot = await getDoc(userregisterEmailRef);
    return docSnapshot.exists();
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    let nicknameExists;
    let emailExists;
    if (nickname !== "") {
      nicknameExists = await checkNicknameExists(nickname);
    }

    if (registerEmail !== "") {
      emailExists = await checkEmailExists(registerEmail);
    }

    if (!name) {
      Toast.fire({
        icon: "error",
        title: "Name is required",
      });
    } else if (!nickname) {
      Toast.fire({
        icon: "error",
        title: "Username is required",
      });
    } else if (!isValidNickname(nickname)) {
      Toast.fire({
        icon: "error",
        title: "Username must be lowercase letters, max 0f 14 letters",
      });
    } else if (nickname !== "" && nicknameExists) {
      Toast.fire({
        icon: "error",
        title: "Username is already taken. Please choose another one.",
      });
    } else if (!number) {
      Toast.fire({
        icon: "error",
        title: "Phone number is required",
      });
    } else if (!registerEmail) {
      Toast.fire({
        icon: "error",
        title: "Email is required",
      });
    } else if (!isValidEmail(registerEmail)) {
      Toast.fire({
        icon: "error",
        title: "Enter a valid email format",
      });
    } else if (registerEmail !== "" && emailExists) {
      Toast.fire({
        icon: "error",
        title: "Email is already in use. Please log in.",
      });
    } else if (!registerPassword) {
      Toast.fire({
        icon: "error",
        title: "Password is required",
      });
    } else {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#_\\/])[A-Za-z\d@$!%*?&#_\\/]{8,}$/;
      if (!passwordRegex.test(registerPassword)) {
        Toast.fire({
          icon: "error",
          title:
            "Password must be at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character any (@$!%*?&#_)",
        });
      } else if (!confirmPassword) {
        Toast.fire({
          icon: "error",
          title: "Confirm Password is required",
        });
      } else if (registerPassword !== confirmPassword) {
        Toast.fire({
          icon: "error",
          title: "Passwords do not match",
        });
      } else if (!isMember) {
        Toast.fire({
          icon: "error",
          title: "You must be a member of our community",
        });
      } else {
        try {
          setloading(true);
          await register(
            registerEmail,
            registerPassword,
            name,
            nickname,
            number
          );
          setShowConfirmEmail(true);
          Toast.fire({
            icon: "success",
            title: "Registration successful",
          });
          setloading(false);
        } catch (error) {
          console.error("Registration error:", error);
          if (error.code === "auth/email-already-in-use") {
            Toast.fire({
              icon: "error",
              title: "Email already exists. Please log in.",
            });
          } else {
            Toast.fire({
              icon: "error",
              title: "An error occurred. Please try again.",
            });
          }
        } finally {
          setloading(false);
        }
      }
    }
  };


  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidNickname = (nickname) => {
    const nicknameRegex = /^[a-z0-9]{1,14}$/;
    return nicknameRegex.test(nickname);
  };

  const handleCheckboxChange = () => {
    const value = isMember ? false : true;
    setIsMember(value);
  };

  return (
    <div className="form-container">
      {showConfirmEmail ? (
        <NotMember />
      ) : (
        <div>
          <form className="form">
            <div className="form-title">Register</div>
            <Input
              title="Name"
              svg={UserSvg}
              placeholder="Enter your Full Name"
              type="text"
              value={name}
              id={"name"}
              name={"name"}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              title="Username"
              svg={UserSvg}
              placeholder="Enter your Username"
              id="username"
              name="username"
              type="text"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
            <Input
              title="Email"
              svg={EmailSvg}
              placeholder="Enter your Email"
              type="text"
              id="email"
              name="email"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
            />

            <Input
              title="Phone Number"
              svg={PhoneSvg}
              placeholder="Enter your Phone Number"
              type="tel"
              value={number}
              id={"number"}
              name={"number"}
              onChange={(e) => setNumber(e.target.value)}
            />
            <Input
              title="Password"
              svg={PasswordSvg}
              placeholder="Enter your Password"
              type="password"
              id="password"
              name="password"
              autoComplete="password"
              value={registerPassword}
              onChange={(e) => setRegisterPassword(e.target.value)}
            />
            <Input
              title="Confirm Password"
              svg={PasswordSvg}
              placeholder="Enter your Password"
              type="password"
              id="confirm-password"
              name="confirm-password"
              autoComplete="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className="radio-flex">
              <input
                type="checkbox"
                className="lp-checkbox sc-checkbox"
                checked={isMember}
                onChange={handleCheckboxChange}
              />
              <label>Are you a member of {ProjectName}?</label>
            </div>
            <HomeButton
              text={"Register"}
              loading={loading}
              onClick={!loading ? handleRegister : undefined}
            />
            <p className="p">
              Have an account?{" "}
              <Link to={"/login"} className="span">
                Log In
              </Link>
            </p>
          </form>
        </div>
      )}
    </div>
  );
};

export default Register;
