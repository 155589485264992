import React from "react";
import Profile from "../../components/cards/Profile";

const ViewProfile = () => {
  return (
    <div>
      <Profile />
    </div>
  );
};

export default ViewProfile;
