import React from "react";
import { Link } from "react-router-dom";
import ThemeToggle from "./general/ThemeToggle";
import { useGeneralBrain } from "../controller/Brain";
import { useAuth } from "../contexts/AuthContext";
import SideLink from "./cards/SideLink";
import {
  AboutSvg,
  AdminSvg,
  HelpSvg,
  HomeSvg,
  LogOutSvg,
  LoginSvg,
  MatrimonySvg,
  RegisterSvg,
  SettingSvg,
} from "../data/Data";

const Navbar = ({ open, setOpen }) => {
  const { currentUser, isAdmin, userData } = useGeneralBrain();

  const { logout } = useAuth();
  const openNav = () => {
    setOpen(true);
  };

  const closeNav = () => {
    setOpen(false);
  };

  return (
    <div className="navbar-container">
      <Link to="/" className="navbar-logo-name">
        <div className="navbar-logo-img">
          <img src="/logo.png" alt="logo" className="navbar-logo" />
        </div>
        {currentUser && userData?.isMember && (
          <div className="logo-name">
            Hi <span className="logo-nickname">{ userData?.userName}</span>
          </div>
        )}
      </Link>
      <div className="navbar-links">
        <ThemeToggle />
        {currentUser && (
          <Link to="/" className="navbar-link dart">
            Home
          </Link>
        )}
        {currentUser && userData?.isSubcribed && userData?.isMember && (
          <Link to="/matrimony" className="navbar-link dart">
            Matrimony
          </Link>
        )}
        <Link to="/about" className="navbar-link dart">
          About
        </Link>
        <Link to="/help" className="navbar-link dart">
          Help
        </Link>
        {currentUser && isAdmin && userData?.isMember && (
          <Link to="/admin/overview" className="navbar-link dart">
            Admin
          </Link>
        )}
        {!currentUser && (
          <>
            <Link to="/login" className="navbar-link dart">
              Log In
            </Link>
            <Link to="/register" className="navbar-link dart">
              Register
            </Link>
          </>
        )}

        {currentUser && userData?.isMember && (
          <>
            <Link to="/settings" className="navbar-link dart">
              Settings
            </Link>
           
          </>
        )}

        {currentUser && (
          <div
            onClick={async (e) => {
              e.preventDefault();
              await logout();
            }}
            className="navbar-link dart"
          >
            LogOut
          </div>
        )}

        <div className="navbar-authlink">
          <div className="navbar-burger" onClick={openNav}>
            <div className="navbar-line"></div>
            <div className="navbar-line"></div>
            <div className="navbar-line"></div>
          </div>

          <div className={`navbar-sidenav ${open ? "navbar-open" : ""}`}>
            <div className="navbar-close" onClick={closeNav}>
              <span className="navbar-closechild">&times;</span>
            </div>
            {currentUser && <SideLink text={"Home"} url={"/"} svg={HomeSvg} />}

            {currentUser && userData?.isSubcribed && userData?.isMember && (
              <SideLink
                text={"Matrimony"}
                url={"/matrimony"}
                svg={MatrimonySvg}
              />
            )}

            <SideLink text={"About"} url={"/about"} svg={AboutSvg} />
            <SideLink text={" Help"} url={"/help"} svg={HelpSvg} />
            {currentUser && isAdmin && userData?.isMember && (
              <SideLink text={"Admin"} url={"/admin/overview"} svg={AdminSvg} />
            )}
            {!currentUser && (
              <>
                <SideLink text={" Log In"} url={"/login"} svg={LoginSvg} />
                <SideLink
                  text={"Register"}
                  url={"/register"}
                  svg={RegisterSvg}
                />
              </>
            )}

            {currentUser && userData?.isMember && (
              <>
                <SideLink
                  text={"Settings"}
                  url={"/settings"}
                  svg={SettingSvg}
                />
              </>
            )}
            {currentUser && (
              <>
                <SideLink
                  text={"LogOut"}
                  onClick={async (e) => {
                    e.preventDefault();
                    await logout();
                  }}
                  svg={LogOutSvg}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
