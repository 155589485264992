import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  serverTimestamp,
  runTransaction,
  collection,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { Toast, useGeneralBrain } from "../../controller/Brain";
import { useAuth } from "../../contexts/AuthContext";

const ApproveMatrimony = () => {
  const { query } = useParams();
  const { userData } = useAuth();
  const { generateUid8, calculateAge } = useGeneralBrain();
  const [userId, nickname, email] = query.split("*").slice(-3);
  const [loading, setLoading] = useState(false);

  const handleApprove = async () => {
    if (!userData?.isAdmin) { 
      Toast.fire({
        title: "Insufficient Permission",
        text: "You do not have permission to approve users.",
        icon: "error",
      });
      return;
    }

    setLoading(true);

    const matrimonyRef = doc(db, "matrimonys", userId);
    const DocSnap = await getDoc(matrimonyRef);
    if (!DocSnap.exists()) {
      throw new Error("No document");
    }

    const matriData = DocSnap.data();

    try {
      await runTransaction(db, async (transaction) => {
        const userRef = doc(db, "users", userId);
        transaction.update(userRef, { isSubcribed: true });

        const shortlistsRef = doc(db, "liveShortlists", userId);
        transaction.set(shortlistsRef, {});

        const liveShortlistIdsRef = doc(db, "liveShortlistIds", userId);
        transaction.set(liveShortlistIdsRef, {});

        const matrimonyRef = doc(db, "matrimonys", userId);
        transaction.update(matrimonyRef, {
          status: "approved",
        });

        const code = "code";
        const dociRef = doc(db, "info", "users");
        transaction.update(dociRef, {
          [`${userId}.${code}`]: 1111000,
        });

        const emailIdRef = doc(db, "mails", "matrimonys");
        transaction.update(emailIdRef, {
          matrimonys: arrayUnion(email),
        });

        const live1 = matriData.gender === "Male" ? "livemale1" : "livefemale1";
        const liveMatrimonysRef = doc(db, "liveMatrimonys", live1);
        transaction.update(liveMatrimonysRef, {
          [userId]: {
            d101: matriData.name,
            d102: calculateAge(matriData.birthDate), 
            d103: matriData.job,
            d104: matriData.location,
            d105: matriData.imgURLs[0],
            d106: userId,
            d107: "approved",
            d108: matriData.gender,
          },
        });

        const emailRef = collection(db, "sendEmails");
        const docRef = doc(emailRef);
        transaction.set(docRef, {
          recipient: email,
          subject: "Congratulation",
          title: `Hi ${nickname}`,
          content: `Your Matrimony Profile has been approved`,
          link: "nevewanisamaj.in",
          timestamp: serverTimestamp(),
        });

        // Log the approval action
        const uniqueLogId = generateUid8();
        const logRef = doc(db, "info", "logs");
        transaction.update(logRef, {
          [uniqueLogId]: {
            info: `An admin just approved a matrimony from email ${email}`,
            time: serverTimestamp(),
            username: userData?.userNickname,
          },
        });
      });
      Toast.fire({
        title: "Success!",
        text: "User approved successfully.",
        icon: "success",
      });
    } catch (error) {
      console.error("Error approving user:", error);
      Toast.fire({
        title: "Error",
        text: "Failed to approve user.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <div className="form box-shadow">
        <div className="confirm-container">
          <h2 className="confirm-title">Approve Matrimony</h2>
          <div className="">
            <p className="confirm-text">
              Click approve user matromony profile to approve. And send an email
              to let them know they have been approved.
            </p>
          </div>
          <div>
            <span
              onClick={handleApprove}
              className="confirm-button"
              style={{ cursor: loading ? "not-allowed" : "pointer" }}
            >
              {loading ? "Approving Matrimony..." : "Approve Matrimony"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveMatrimony;
