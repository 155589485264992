import React, { } from "react";
import { ProjectName } from "../data/Data";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-container">
      <div className="footer-parent">
        <div className="footer-end">
          <div>
            ©{currentYear}. {ProjectName} All rights reserved.
          </div>
          <div>Made with ♥</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
