import React, { useEffect, useState } from "react";
import { StarIcon1 } from "../../data/Data";
import { useLocation } from "react-router-dom";
import {
  doc,
  onSnapshot,
  runTransaction,  
} from "firebase/firestore";
import { db, storage } from "../../utils/init-firebase";
import {
  Toast,
  showConfirmDialog,
  useGeneralBrain,
} from "../../controller/Brain";
import Swal from "sweetalert2";
import PageWait from "../general/PageWait";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const Profile = ({ onClick, id }) => {
  const { userData, calculateAge, TimeAgo, userId } = useGeneralBrain();
  const islocation = useLocation();
  const [loading, setLoading] = useState(true); 
  const path = islocation.pathname;
  const [data, setData] = useState({}); 
  const [name, setName] = useState("");
  const [age, setAge] = useState(0);
  const [time, setTime] = useState("");
  const [location, setLocation] = useState("");
  const [gotra, setGotra] = useState(""); 
  const [bloodGroup, setBloodGroup] = useState("");


  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("");
  const [imgURLs, setImgURLs] = useState([]);
  const [imgURLsupload, setImgURLsupload] = useState([]);
  const [height, setHeight] = useState(0);
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setbirthDate] = useState("");
  const [birthDay, setBirthDay] = useState('');
  const [birthTime, setbirthTime] = useState("");
  const [birthPlace, setbirthPlace] = useState("");
  const [education, seteducation] = useState("");
  const [hobbies, sethobbies] = useState("");


  const [occupation, setOccupation] = useState("");
  const [organization, setOrganization] = useState("");
  const [workingAs, setWorkingAs] = useState("");
  const [workplace, setWorkplace] = useState("");
  const [income, setincome] = useState("");
  const [jobinfo, setjobinfo] = useState("");
  const [bussiness, setBussiness] = useState("");

  const [fathername, setfathername] = useState("");
  const [fatherprofession, setfatherprofession] = useState("");
  const [mothername, setmothername] = useState("");
  const [motherprofession, setmotherprofession] = useState("");
  const [faddress, setfaddress] = useState("");
  const [fphone, setfphone] = useState("");
  const [fmobile, setfmobile] = useState("");


  const [loading1, setloading1] = useState("");

  const [isEditingbirthDate, setIsEditingbirthDate] = useState(false);
  const [isEditingbirthTime, setIsEditingbirthTime] = useState(false);

  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingOccupation, setIsEditingOccupation] = useState(false);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [isEditingHeight, setIsEditingHeight] = useState(false);
  const [isEditinggotra, setIsEditinggotra] = useState(false);
  const [isEditingbloodGroup, setIsEditingbloodGroup] = useState(false);
  const [isEditingorganization, setIsEditingorganization] = useState(false);
  const [isEditingworkingAs, setIsEditingworkingAs] = useState(false);
  const [isEditingworkplace, setIsEditingworkplace] = useState(false);

  const [isEditingaddress, setIsEditingaddress] = useState(false);
  const [isEditingphone, setIsEditingphone] = useState(false);
  const [isEditingmobile, setIsEditingmobile] = useState(false);
  const [isEditingemail, setIsEditingemail] = useState(false);
  const [isEditingbirthPlace, setIsEditingbirthPlace] = useState(false);
  const [isEditingeducation, setIsEditingeducation] = useState(false);
  const [isEditinghobbies, setIsEditinghobbies] = useState(false);
  const [isEditingincome, setIsEditingincome] = useState(false);
  const [isEditingjobinfo, setIsEditingjobinfo] = useState(false);
  const [isEditingbussiness, setIsEditingbussiness] = useState(false);
  const [isEditingfathername, setIsEditingfathername] = useState(false);
  const [isEditingfatherprofession, setIsEditingfatherprofession] = useState(false);
  const [isEditingmothername, setIsEditingmothername] = useState(false);
  const [isEditingmotherprofession, setIsEditingmotherprofession] = useState(false);
  const [isEditingfaddress, setIsEditingfaddress] = useState(false);
  const [isEditingfphone, setIsEditingfphone] = useState(false);
  const [isEditingfmobile, setIsEditingfmobile] = useState(false);


  const isEditingAllowed = path.includes("/matrimony/manage/profile");

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imgURLs.length);
  };
  
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imgURLs.length) % imgURLs.length);
  };

  const handleviewimage = (index) => {
    setCurrentImageIndex(index);
  };
  

  useEffect(() => {
    const fetchLogs = () => {
      try {
        const docRef = doc(db, "matrimonys", id || userData?.userId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            const info = doc.data();
            setData(info)
            setName(info.name);
            setTime(TimeAgo(info.time));
            setOccupation(info.job);
            setLocation(info.location);
            setGotra(info.gotra);
            setBloodGroup(info.bloodGroup);
            setGender(info.gender);
            setHeight(info.height);
            setStatus(info.status);
            setImgURLs(info.imgURLs);
            setImgURLsupload(info.imgURLs)
            setOrganization(info.organization);
            setWorkingAs(info.workingAs);
            setWorkplace(info.workplace);
            setAddress(info.residingAddress);
            setPhone(info.phone);
            setMobile(info.mobile);
            setEmail(info.email);
            setbirthDate(info.birthDate)
            setbirthTime(info.birthTime)
            setbirthPlace(info.birthPlace);
            seteducation(info.education);
            sethobbies(info.hobbies);
            setincome(info.annualIncome);
            setjobinfo(info.jobInfo);
            setBussiness(info.business);
            setfathername(info.fathersName);
            setfatherprofession(info.fathersProfession);
            setmothername(info.mothersName);
            setmotherprofession(info.mothersProfession);
            setfaddress(info.fathersAddress);
            setfphone(info.fathersPhone);
            setfmobile(info.fathersMobile);


            setLoading(false);
          }
        });
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };
    const unsubscribe = fetchLogs();
    return () => unsubscribe();
  }, [id, userData, TimeAgo]);


  useEffect(() => {
    setAge(calculateAge(birthDate));
  }, [birthDate, calculateAge]);

  useEffect(() => {
    if (birthDate) {
      const date = new Date(birthDate);
      const dayIndex = date.getDay();
      const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      setBirthDay(weekdays[dayIndex]);
    }
  }, [birthDate]);
  

  const handleSaveProfile = async () => {

    if (imgURLs.length === 0) {
        Toast.fire({
            icon: 'error',
            title: 'No images!',
            text: 'You must upload at least one image before saving your profile.',
        });
        return;
    }

    const isConfirmed = await showConfirmDialog({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
    });

    if (isConfirmed) {

      setloading1(true)

      const newImages = imgURLsupload.filter(image => typeof image !== 'string');

      // Filter out the existing URLs from imgURLsupload
      const existingURLs = imgURLsupload.filter(image => typeof image === 'string');

       
        const uploadTasks = newImages.map((image) => {
            const storageRef = ref(storage, `matrimony/${userId}/${image.name}`);
            return uploadBytesResumable(storageRef, image);
        });

        await Promise.all(uploadTasks);

        // Get download URLs for new images
        const newImageURLs = await Promise.all(
            newImages.map(async (image) => {
                const storageRef = ref(storage, `matrimony/${userId}/${image.name}`);
                return await getDownloadURL(storageRef);
            })
        );

        const finalImageURLs = [...existingURLs, ...newImageURLs];

        try {
            await runTransaction(db, async (transaction) => {
                const live1 = userData.gender === "Male" ? "livemale1" : "livefemale1";
                const liveMatrimonysRef = doc(db, "liveMatrimonys", live1);
                const matrimonysRef = doc(db, "matrimonys", userData.userId);

                // Update liveMatrimonys
                transaction.update(liveMatrimonysRef, {
                    [userData.userId]: {
                        d101: name,
                        d102: age,
                        d103: occupation,
                        d104: location,
                        d105: finalImageURLs[0],  // Use the first URL from finalImageURLs
                        d106: userData.userId,
                        d107: status,
                        d108: gender,
                    },
                });

                // Update user profile
                transaction.update(matrimonysRef, {
                    number: phone,
                    email: email,
                    time: data.time,
                    imgURLs: finalImageURLs,  // Updated image URLs
                    gender: data.gender,
                    name: name,
                    status: data.status,
                    location: location,
                    residingAddress: address,
                    birthDate: birthDate,
                    birthDay: birthDay,
                    birthTime: birthTime,
                    birthPlace: birthPlace,
                    height: height,
                    gotra: gotra,
                    bloodGroup: bloodGroup,
                    education: education,
                    hobbies: hobbies,
                    job: occupation,
                    organization: organization,
                    workingAs: workingAs,
                    workplace: workplace,
                    annualIncome: income,
                    jobInfo: jobinfo,
                    business: bussiness,
                    fathersName: fathername,
                    fathersProfession: fatherprofession,
                    mothersName: mothername,
                    mothersProfession: motherprofession,
                    fathersAddress: faddress,
                    fathersPhone: fphone,
                    fathersMobile: fmobile,
                });
            });

            setloading1(false)

            Toast.fire({
                icon: "success",
                title: `Profile Saved successfully`,
            }).then(() => {});
        } catch (error) {
            console.error("Error", error);
            Swal.fire("Error!", " Please try again.", "error").then(() => {});
        }
    }
};


  if (loading) {
    return <PageWait />; 
  }


const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => URL.createObjectURL(file));
    setImgURLs((prevImages) => [...prevImages, ...newImages]);
    setImgURLsupload((prevFiles) => [...prevFiles, ...files]); // Store file objects
};

  

const removeImage = (index) => {
  const updatedImages = [...imgURLs];
  updatedImages.splice(index, 1);
  setImgURLs(updatedImages);

  const updatedFiles = [...imgURLsupload];
  updatedFiles.splice(index, 1);
  setImgURLsupload(updatedFiles);

  if (updatedImages.length === 0) {
      setCurrentImageIndex(0);
  } else if (currentImageIndex >= index) {
      setCurrentImageIndex((prevIndex) =>
          prevIndex === 0 ? 0 : prevIndex - 1
      );
  }
};
  

  return (
    <>
      <div className="block-back">
        <div onClick={onClick} className="all-back-button">
          Back
        </div>
      </div>
      <div className="profile-container">
        <div className="profile-header  box-shadow">
          <span className="profile-star-icon">
            <StarIcon1 />
          </span>

          <div className="carousel">
    {imgURLs.length > 0 ? (
        <img
            src={imgURLs[currentImageIndex]}
            alt="profile pic"
            className="profile-image-dot"
            
        />
    ) : (
        <div className="no-image-placeholder">No images available</div>
    )}
    {imgURLs.length > 0 && (
        <div className="container-next-prev">
            <div className="next-prev" onClick={handlePrevImage}>&lt;</div>
            <div className="next-prev" onClick={handleNextImage}>&gt;</div>
        </div>
    )}
</div>
        <div className="image-container-scope">
  {imgURLs.map((image, index) => (
    <div key={index} className="preview-container">
      <img
        src={image.url || image}
        alt="Preview"
        className="preview-pfp"
        onClick={() => handleviewimage(index)}
      />
      {isEditingAllowed && (
        <div
          className="remove-btn"
          onClick={() => removeImage(index)}
        >
          <div>x</div>
        </div>
      )}
    </div>
  ))}
  {imgURLs.length < 4 && isEditingAllowed && (
    <label htmlFor="profileImage" className="add-image">
      <span>+</span>
      <input
        id="profileImage"
        type="file"
        name="profileImage"
        accept="image/*"
        onChange={handleImageChange}
      />
    </label>
  )}
</div>
          <div className="profile-header-info">
            <div className="profile-name">
              {isEditingName ? (
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => setIsEditingName(false)}
                  autoFocus
                />
              ) : (
                <>
                  {name}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingName(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-age">
                  {age} yrs{" "}
            </div>
          </div>
          <div className="profile-last-online">
            <div>Member Since</div>
            <div>{time}</div>
          </div>
        </div>
        <div className="profile-details box-shadow">
          <div className="profile-status ">
            <div className="flex-center capitalize themecom box-shadow border5">
              {gender}
            </div>
            <div className="flex-center capitalize themecom box-shadow border5">
              {status}
            </div>
          </div>
          <div className="profile-info"> 
          <div className="pro-til">Basic Details</div>
          <div className="profile-gang">
              <span className="profile-title">Birth Date:</span>
              {isEditingbirthDate ? (
                <input
                  type="date"
                  value={birthDate}
                  onChange={(e) => setbirthDate(e.target.value)}
                  onBlur={() => setIsEditingbirthDate(false)}
                  autoFocus
                />
              ) : (
                <>
                  {birthDate}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingbirthDate(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Birth Day:</span>
              {birthDay}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Birth Time:</span>
              {isEditingbirthTime ? (
                <input
                  type="time"
                  value={birthTime}
                  onChange={(e) => setbirthTime(e.target.value)}
                  onBlur={() => setIsEditingbirthTime(false)}
                  autoFocus
                />
              ) : (
                <>
                  {birthTime}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingbirthTime(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Location:</span>
              {isEditingLocation ? (
                <input
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  onBlur={() => setIsEditingLocation(false)}
                  autoFocus
                />
              ) : (
                <>
                  {location}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingLocation(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>

            <div className="profile-gang">
              <span className="profile-title">Address:</span>
              {isEditingaddress ? (
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  onBlur={() => setIsEditingaddress(false)}
                  autoFocus
                />
              ) : (
                <>
                  {address}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingaddress(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Phone:</span>
              {isEditingphone ? (
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onBlur={() => setIsEditingphone(false)}
                  autoFocus
                />
              ) : (
                <>
                  {phone}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingphone(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Mobile:</span>
              {isEditingmobile ? (
                <input
                  type="text"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  onBlur={() => setIsEditingmobile(false)}
                  autoFocus
                />
              ) : (
                <>
                  {mobile}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingmobile(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Email:</span>
              {isEditingemail ? (
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => setIsEditingemail(false)}
                  autoFocus
                />
              ) : (
                <>
                  {email}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingemail(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Birth Place:</span>
              {isEditingbirthPlace ? (
                <input
                  type="text"
                  value={birthPlace}
                  onChange={(e) => setbirthPlace(e.target.value)}
                  onBlur={() => setIsEditingbirthPlace(false)}
                  autoFocus
                />
              ) : (
                <>
                  {birthPlace}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingbirthPlace(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Education:</span>
              {isEditingeducation ? (
                <input
                  type="text"
                  value={education}
                  onChange={(e) => seteducation(e.target.value)}
                  onBlur={() => setIsEditingeducation(false)}
                  autoFocus
                />
              ) : (
                <>
                  {education}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingeducation(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Hobbies:</span>
              {isEditinghobbies ? (
                <input
                  type="text"
                  value={hobbies}
                  onChange={(e) => sethobbies(e.target.value)}
                  onBlur={() => setIsEditinghobbies(false)}
                  autoFocus
                />
              ) : (
                <>
                  {hobbies}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditinghobbies(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <div className="profile-title">Height:</div>
              {isEditingHeight ? (
                <input
                  type="number"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  onBlur={() => setIsEditingHeight(false)}
                  autoFocus
                />
              ) : (
                <>
                  {height}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingHeight(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>

            <div className="profile-gang">
              <div className="profile-title">Gotra:</div>
              {isEditinggotra ? (
                <input
                  type="text"
                  value={gotra}
                  onChange={(e) => setGotra(e.target.value)}
                  onBlur={() => setIsEditinggotra(false)}
                  autoFocus
                />
              ) : (
                <>
                  {gotra}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditinggotra(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>

            <div className="profile-gang">
              <div className="profile-title">Blood Group:</div>
              {isEditingbloodGroup ? (

<select
name="bloodGroup"
value={bloodGroup}
onChange={(e) => setBloodGroup(e.target.value)}
onBlur={() => setIsEditingbloodGroup(false)}
                  autoFocus
required
>
<option value="">Select</option>
<option value="A+">A+</option>
<option value="A-">A-</option>
<option value="B+">B+</option>
<option value="B-">B-</option>
<option value="AB+">AB+</option>
<option value="AB-">AB-</option>
<option value="O+">O+</option>
<option value="O-">O-</option>
</select>
                
              ) : (
                <>
                  {bloodGroup}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingbloodGroup(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="pro-til">Professional Details</div>
            <div className="profile-gang">
              <span className="profile-title">Job:</span>
              {isEditingOccupation ? (
                <input
                  type="text"
                  value={occupation}
                  onChange={(e) => setOccupation(e.target.value)}
                  onBlur={() => setIsEditingOccupation(false)}
                  autoFocus
                />
              ) : (
                <>
                  {occupation}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingOccupation(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <div className="profile-title">Organization:</div>
              {isEditingorganization ? (
                <input
                  type="text"
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  onBlur={() => setIsEditingorganization(false)}
                  autoFocus
                />
              ) : (
                <>
                  {organization}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingorganization(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>

            <div className="profile-gang">
              <div className="profile-title">Working As:</div>
              {isEditingworkingAs ? (
                <input
                  type="text"
                  value={workingAs}
                  onChange={(e) => setWorkingAs(e.target.value)}
                  onBlur={() => setIsEditingworkingAs(false)}
                  autoFocus
                />
              ) : (
                <>
                  {workingAs}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingworkingAs(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>

            <div className="profile-gang">
              <div className="profile-title">Workplace:</div>
              {isEditingworkplace ? (
                <input
                  type="text"
                  value={workplace}
                  onChange={(e) => setWorkplace(e.target.value)}
                  onBlur={() => setIsEditingworkplace(false)}
                  autoFocus
                />
              ) : (
                <>
                  {workplace}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingworkplace(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Annual Income:</span>
              {isEditingincome ? (
                <input
                  type="text"
                  value={income}
                  onChange={(e) => setincome(e.target.value)}
                  onBlur={() => setIsEditingincome(false)}
                  autoFocus
                />
              ) : (
                <>
                  {income}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingincome(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Job Info:</span>
              {isEditingjobinfo ? (

<select
name="jobInfo"
value={jobinfo}
required
onChange={(e) => setjobinfo(e.target.value)}
onBlur={() => setIsEditingjobinfo(false)}
autoFocus
>
<option value="">Select</option>
<option value="Permanent">Permanent</option>
<option value="Temporary">Temporary</option>
</select>
              ) : (
                <>
                  {jobinfo}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingjobinfo(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Bussiness:</span>
              {isEditingbussiness ? (
                <input
                  type="text"
                  value={bussiness}
                  onChange={(e) => setBussiness(e.target.value)}
                  onBlur={() => setIsEditingbussiness(false)}
                  autoFocus
                />
              ) : (
                <>
                  {bussiness}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingbussiness(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
<div className="pro-til">Family Details</div>
            <div className="profile-gang">
              <span className="profile-title">Fathers Name:</span>
              {isEditingfathername ? (
                <input
                  type="text"
                  value={fathername}
                  onChange={(e) => setfathername(e.target.value)}
                  onBlur={() => setIsEditingfathername(false)}
                  autoFocus
                />
              ) : (
                <>
                  {fathername}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingfathername(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Fathers profession:</span>
              {isEditingfatherprofession ? (
                <input
                  type="text"
                  value={fatherprofession}
                  onChange={(e) => setfatherprofession(e.target.value)}
                  onBlur={() => setIsEditingfatherprofession(false)}
                  autoFocus
                />
              ) : (
                <>
                  {fatherprofession}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingfatherprofession(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Mothers Name:</span>
              {isEditingmothername ? (
                <input
                  type="text"
                  value={mothername}
                  onChange={(e) => setmothername(e.target.value)}
                  onBlur={() => setIsEditingmothername(false)}
                  autoFocus
                />
              ) : (
                <>
                  {mothername}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingmothername(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Mothers Profession:</span>
              {isEditingmotherprofession ? (
                <input
                  type="text"
                  value={motherprofession}
                  onChange={(e) => setmotherprofession(e.target.value)}
                  onBlur={() => setIsEditingmotherprofession(false)}
                  autoFocus
                />
              ) : (
                <>
                  {motherprofession}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingmotherprofession(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Address:</span>
              {isEditingfaddress ? (
                <input
                  type="text"
                  value={faddress}
                  onChange={(e) => setfaddress(e.target.value)}
                  onBlur={() => setIsEditingfaddress(false)}
                  autoFocus
                />
              ) : (
                <>
                  {faddress}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingfaddress(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Phone:</span>
              {isEditingfphone ? (
                <input
                  type="text"
                  value={fphone}
                  onChange={(e) => setfphone(e.target.value)}
                  onBlur={() => setIsEditingfphone(false)}
                  autoFocus
                />
              ) : (
                <>
                  {fphone}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingfphone(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="profile-gang">
              <span className="profile-title">Mobile:</span>
              {isEditingfmobile ? (
                <input
                  type="text"
                  value={fmobile}
                  onChange={(e) => setfmobile(e.target.value)}
                  onBlur={() => setIsEditingfmobile(false)}
                  autoFocus
                />
              ) : (
                <>
                  {fmobile}{" "}
                  {isEditingAllowed && (
                    <span
                      className="edit-icon"
                      onClick={() => setIsEditingfmobile(true)}
                    >
                      ✎
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          {isEditingAllowed && (
            <div>
              <div className="delete-icon theme1" onClick={handleSaveProfile}>
                {loading1 ? "Uploading..." : "Save Profile"}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
