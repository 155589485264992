import React, { useEffect, useState } from "react";
import ProfileCard from "../../components/cards/ProfileCard";
import { useNavigate } from "react-router-dom";
import { UserIcon, UsersIcon } from "../../data/Data";
import { ButtonIcon } from "../../controller/components";
import { db } from "../../utils/init-firebase";
import { doc, getDoc} from "firebase/firestore";
import { useGeneralBrain } from "../../controller/Brain";
import Profile from "../../components/cards/Profile";
import NothingYet from "../../components/general/NothingYet";
import PageWait from "../../components/general/PageWait";

const ViewShorlist = () => {
  const navigate = useNavigate();
  const { userData } = useGeneralBrain();
  const [showProfile, setShowProfile] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true); 
  const [id, setId] = useState("");
  const [myData, setMyData] = useState([]);
  const [info, setInfo] = useState({});

  const handleViewProfile = () => {
    setShowProfile(false);
  };

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const live1 = userData?.gender === "Male" ? "livefemale1" : "livemale1";
        const docRef = doc(db, "liveMatrimonys", live1);
        const liveShortlistIdsRef = doc(
          db,
          "liveShortlistIds",
          userData.userId
        );
        const docSnap = await getDoc(docRef);
        const liveShortlistIdsSnap = await getDoc(liveShortlistIdsRef);

        if (liveShortlistIdsSnap.exists()) {
          const logData = liveShortlistIdsSnap.data().ids || [];
          setMyData(logData);
        }

        if (docSnap.exists()) {
          const logData = docSnap.data();
          setData(logData);
        }

        setLoading(false); 
      } catch (error) {
        console.error("Error fetching logs:", error);
        setLoading(false); 
      }
    };

    fetchLogs();
  }, [userData]);

  useEffect(() => {
    if (data && myData) {
      const filteredDatas = Object.entries(data)
        .filter(([d106, d]) => {
          return myData.includes(d106) && d.d107 !== "suspended";
        })
        .reduce((obj, [d106, d]) => {
          obj[d106] = d;
          return obj;
        }, {});
  

      let Works = Object.values(filteredDatas);
      setInfo(Works);
    }
  }, [myData, data]);

  return (
    <div className="components">
      <div className="matrimony-first">
        <ButtonIcon
          icon={UsersIcon}
          text={"View Profile"}
          onClick={() => navigate("/matrimony")}
          rounded={true}
        />
        <ButtonIcon
          icon={UserIcon}
          text={"Manage My Profile"}
          onClick={() => navigate("/matrimony/manage/profile")}
          rounded={true}
        />
      </div>

      {loading && <PageWait />} {/* Render PageWait while loading */}

      {!showProfile && !loading && (
        <>
          {Object.keys(info).length > 0 ? (
            <div className="profile-card-container">
              {Object.keys(info).map((key) => (
                <ProfileCard
                  key={key}
                  setShowProfile={setShowProfile}
                  data={info[key]}
                  setId={setId}
                />
              ))}
            </div>
          ) : (
            <NothingYet />
          )}
        </>
      )}
      {showProfile && <Profile onClick={handleViewProfile} id={id} />}
    </div>
  );
};

export default ViewShorlist;
